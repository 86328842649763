import * as React from "react";

function SvgGear(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 1000 1000" {...props}>
      <path d="M500 342c-87 0-158 71-158 158s71 158 158 158 158-71 158-158-71-158-158-158m0-66h2c122 1 222 102 222 224 0 123-101 224-224 224S276 623 276 500s101-224 224-224M459 91c-10 0-19 8-21 19l-10 62c-3 13-11 23-24 27-17 5-36 13-54 22-11 6-25 5-35-3l-49-35c-10-6-23-4-29 2l-55 55c-8 8-9 18-3 26l1 1 37 52c7 10 8 24 2 35-9 17-17 35-22 54-4 12-14 21-26 23l-61 11c-11 1-19 11-19 21v78c0 10 8 19 19 21l62 10c13 3 23 12 27 24 5 18 13 36 22 54 6 11 5 25-3 35l-35 49c-7 10-6 21 1 28l1 1 54 54c11 9 21 9 27 4 1 0 1-1 1-1l52-37c10-7 24-8 35-2 16 9 34 16 52 21 12 4 21 14 23 26l11 62c2 11 11 19 21 19h78c10 0 19-8 21-19l11-62c2-12 11-22 23-26 18-5 37-13 54-22 11-6 24-5 35 2l50 36c9 6 23 4 29-2l55-55c7-7 8-19 2-26 0-1 0-1-1-1l-36-51c-7-10-8-23-2-34 9-19 17-37 22-55 4-12 14-21 26-23l62-11c11-2 19-11 19-21v-78c0-11-7-19-18-21l-62-11c-12-2-21-10-25-22-7-19-15-38-24-55-6-11-5-25 2-35l37-51c6-8 6-20-2-27l-54-54c-11-9-21-9-29-3l-51 36c-11 7-24 8-35 2-18-10-37-18-56-23-12-4-21-14-23-26l-11-61c-1-11-11-19-21-19h-78m0-66h78c43 0 80 31 87 74l7 41c10 3 20 8 30 12l35-25c34-24 80-19 111 7 0 1 1 2 2 3l55 55-1-1c32 31 35 79 10 113v1l-25 35c4 9 8 18 11 27l43 8c43 7 73 44 73 86v78c0 43-31 79-74 86l-42 8c-4 9-7 18-11 27l25 36-1-1c26 35 22 83-8 113l-56 56h-1c-29 28-75 33-111 9l-35-25c-10 4-19 8-28 11l-8 42c-7 43-43 74-86 74h-78c-43 0-79-31-86-74l-8-42c-8-3-17-7-25-10l-37 25h1c-34 25-80 19-111-8-1 0-2-1-2-1l-55-55h1c-32-30-35-78-11-113v-1l25-34c-4-9-8-18-11-27l-43-8c-43-7-74-44-74-86v-78c0-43 31-80 74-87l41-7c3-9 7-18 11-28l-25-36v1c-26-35-21-84 10-114l-1 1 55-55c30-29 76-34 113-10h1l34 25c9-4 18-8 27-11l8-43c7-41 42-72 83-74h3" />
    </svg>
  );
}

export default SvgGear;
