import * as React from "react";

function SvgChevronLeft(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 1000 1000" {...props}>
      <path d="M275 500c0-7 3-13 7-18l300-300c7-6 16-9 25-7 8 3 15 10 18 18 2 9-1 18-7 25L336 500l282 282c6 6 9 16 7 24-3 9-10 16-18 18-9 3-18 0-25-6L282 518c-4-5-7-11-7-18" />
    </svg>
  );
}

export default SvgChevronLeft;
