import * as React from "react";

function SvgSearch(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#8EE6D0">
          <g>
            <g>
              <g>
                <path
                  d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm3.625 6.58l-.077.063-.001.001-5.223 5.235c-.192.193-.492.215-.708.065l-.077-.064-.001-.001-1.105-1.107c-.217-.218-.568-.218-.786-.001l-.255.256c-.217.217-.216.568 0 .785l1.776 1.776c.433.434 1.137.434 1.57 0h.002l5.869-5.883c.216-.217.216-.568 0-.785l-.276-.276c-.192-.193-.492-.215-.708-.065z"
                  transform="translate(-174 -949) translate(100 805) translate(58 128) translate(16 16)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSearch;
