import * as React from "react";

function GreenTickFA(props) {
  return (
    <svg width="17" height="16" fill="none" {...props}>
      <path
        d="M8.498 16a8 8 0 0 0 7.998-8A8 8 0 1 0 .5 8a8 8 0 0 0 7.998 8Z"
        fill="#00BA88"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.608 5.386c.316.312.32.821.008 1.137l-4.411 4.474a.804.804 0 0 1-1.134.012L4.804 8.803a.804.804 0 0 1 1.121-1.152L7.62 9.3l3.85-3.906a.804.804 0 0 1 1.138-.008Z"
        fill="#fff"
      />
    </svg>
  );
}

export default GreenTickFA;
