import * as React from "react";

function SvgTarget(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 128 128" {...props}>
      <path d="M112 60h-5.69A42.57 42.57 0 0068 21.69V16a4 4 0 00-8 0v5.69A42.57 42.57 0 0021.69 60H16a4 4 0 000 8h5.69A42.57 42.57 0 0060 106.31V112a4 4 0 008 0v-5.69A42.57 42.57 0 00106.31 68H112a4 4 0 000-8zM68 98.26V96a4 4 0 00-8 0v2.26A34.56 34.56 0 0129.74 68H32a4 4 0 000-8h-2.26A34.56 34.56 0 0160 29.74V32a4 4 0 008 0v-2.26A34.56 34.56 0 0198.26 60H96a4 4 0 000 8h2.26A34.56 34.56 0 0168 98.26z" />
      <path d="M64 74a10 10 0 1110-10 10 10 0 01-10 10z" />
    </svg>
  );
}

export default SvgTarget;
