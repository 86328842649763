import * as React from "react";

function SvgEye(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 100 100" {...props}>
      <path d="M96.8 47.9c-.9-1.1-21.1-26.2-46.8-26.2S4.1 46.8 3.2 47.9c-1 1.2-1 2.9 0 4.2.9 1.1 21.1 26.2 46.8 26.2s45.9-25.2 46.8-26.2c.9-1.2.9-3 0-4.2zM50 71.6c-18.4 0-34.5-15.8-39.7-21.6 5.2-5.8 21.3-21.6 39.7-21.6S84.5 44.2 89.7 50C84.5 55.8 68.4 71.6 50 71.6z" />
      <circle cx={50} cy={50} r={15.1} />
    </svg>
  );
}

export default SvgEye;
