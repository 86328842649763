import React from "react";

const CallHrPhoneSVG = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.70426 8.29835C10.2237 10.8171 10.7952 7.90319 12.3994 9.5062C13.9459 11.0523 14.8347 11.362 12.8753 13.3209C12.6299 13.5181 11.0705 15.8911 5.59028 10.4124C0.109382 4.93305 2.481 3.37207 2.6783 3.1267C4.64244 1.16243 4.94686 2.05645 6.49336 3.60252C8.09749 5.20621 5.18484 5.77963 7.70426 8.29835Z"
    />
  </svg>
);

export default CallHrPhoneSVG;
