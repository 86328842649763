import * as React from "react"

const ErrorValidation = (props) => (
  <svg
    {...props}
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.332a6.67 6.67 0 0 0-6.667 6.667A6.669 6.669 0 0 0 8 14.665 6.664 6.664 0 0 0 14.667 8c0-3.68-2.98-6.667-6.667-6.667Z"
      fill="#FB5247"
    />
    <path
      d="M7.994 4.884c.326 0 .586.267.586.587 0 .32-.26.58-.58.58a.582.582 0 0 1-.586-.58c0-.32.26-.587.58-.587ZM8.587 10.534c0 .32-.267.587-.587.587a.587.587 0 0 1-.58-.587V7.588c0-.32.26-.58.58-.58.32 0 .587.26.587.58v2.946Z"
      fill="#fff"
    />
  </svg>
)

export default ErrorValidation
