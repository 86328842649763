import * as React from "react"

const FlexiLogo = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M2.665 14c-.366 0-.68-.13-.941-.392a1.284 1.284 0 0 1-.392-.942V5.333c0-.367.13-.68.392-.941.26-.262.575-.392.941-.392h2.667V2.666c0-.366.13-.68.392-.942.26-.26.575-.391.941-.391h2.667c.367 0 .68.13.942.391.26.262.391.576.391.942V4h2.667c.367 0 .68.13.942.392.26.26.391.574.391.941v7.333c0 .367-.13.681-.391.942-.261.261-.575.392-.942.392H2.665Zm4-10h2.667V2.666H6.665V4Zm6.667 6H9.999v1.333h-4V10H2.665v2.666h10.667V10Zm-6 0h1.333V8.666H7.332V10ZM2.665 8.666H6V7.333h4v1.333h3.333V5.333H2.665v3.333Z"
        fill="#07959E"
      />
    </g>
  </svg>
)

export default FlexiLogo
