import * as React from "react";

const RateCardPrefixIco = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.703 5.446c.306.204.39.618.185.925l-2.03 3.044a1.333 1.333 0 0 1-2.301-.144L7.25 8.66l-2.03 3.044a.667.667 0 0 1-1.11-.74l2.03-3.044a1.333 1.333 0 0 1 2.302.143l.306.612 2.03-3.044a.667.667 0 0 1 .924-.185Z"
      fill="#1B2D93"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.623 1.334h6.754c.591 0 1.1 0 1.508.055.437.058.856.191 1.196.53.34.34.472.76.531 1.196.055.408.055.917.055 1.508v6.755c0 .59 0 1.1-.055 1.508-.059.437-.191.856-.531 1.196-.34.34-.759.472-1.196.53-.407.055-.917.055-1.508.055H4.623c-.591 0-1.1 0-1.508-.054-.437-.06-.856-.192-1.196-.531-.34-.34-.472-.76-.53-1.196-.056-.408-.056-.917-.056-1.508V4.623c0-.59 0-1.1.055-1.508.059-.437.192-.855.531-1.195.34-.34.759-.472 1.196-.531.408-.055.917-.055 1.508-.055ZM3.293 2.71c-.29.04-.38.102-.43.153-.052.05-.114.141-.153.43-.042.308-.043.727-.043 1.374v6.667c0 .647.001 1.066.043 1.374.039.29.101.38.152.43.05.052.141.114.43.153.309.042.727.043 1.375.043h6.667c.647 0 1.065-.001 1.374-.043.289-.039.38-.101.43-.152.051-.051.114-.142.153-.43.041-.31.043-.728.043-1.375V4.667c0-.647-.002-1.066-.043-1.374-.04-.289-.102-.38-.153-.43-.05-.051-.141-.114-.43-.153-.309-.041-.727-.043-1.374-.043H4.667c-.648 0-1.066.002-1.374.043Z"
      fill="#1B2D93"
    />
  </svg>
);

export default RateCardPrefixIco;
