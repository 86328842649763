import React from "react";

const SvgRupee = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/Svg"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#7CAA17"
    />
    <path
      d="M9.23679 12.1911L6.93737 9.45788C7.73682 9.45788 8.36105 9.29305 8.81005 8.96338C9.25906 8.63371 9.51642 8.14286 9.58213 7.49085H10.1641C10.3124 7.49085 10.4429 7.39285 10.4842 7.2504L10.5427 7.04893C10.594 6.87213 10.4922 6.68722 10.3154 6.63592C10.2852 6.62716 10.254 6.62272 10.2225 6.62272H9.5657C9.50364 6.19414 9.3558 5.83151 9.12217 5.5348H10.1641C10.3124 5.5348 10.4429 5.4368 10.4842 5.29435L10.5427 5.09289C10.594 4.91609 10.4922 4.73117 10.3154 4.67987C10.2852 4.67112 10.254 4.66667 10.2225 4.66667H5.85015C5.70254 4.66667 5.57252 4.76375 5.53056 4.90526L5.47084 5.10673C5.41851 5.28323 5.51918 5.46873 5.69568 5.52106C5.72644 5.53017 5.75835 5.5348 5.79042 5.5348H6.88261C7.78062 5.56411 8.31724 5.92675 8.49246 6.62272H5.83523C5.68691 6.62272 5.55643 6.72072 5.5151 6.86317L5.45665 7.06463C5.40535 7.24143 5.50709 7.42635 5.6839 7.47765C5.71408 7.4864 5.74535 7.49085 5.77678 7.49085H8.50342C8.42676 7.82784 8.24606 8.09433 7.96132 8.2903C7.67658 8.48627 7.30606 8.58609 6.84975 8.58975H5.82231C5.63821 8.58975 5.48897 8.73899 5.48897 8.92308C5.48897 8.92397 5.48898 8.92485 5.48898 8.92574L5.49178 9.27656L8.19433 12.4335C8.32099 12.5815 8.50602 12.6667 8.70078 12.6667H9.01539C9.17518 12.6667 9.30472 12.5371 9.30472 12.3774C9.30472 12.3092 9.28066 12.2432 9.23679 12.1911Z"
      fill="white"
    />
  </svg>
);

export default SvgRupee;
