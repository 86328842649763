import * as React from "react";

function SvgChevronDown(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 1000 1000" {...props}>
      <path d="M500 725c-7 0-13-3-18-7L182 418c-6-7-9-16-7-25 3-8 10-15 18-18 9-2 18 1 25 7l282 282 282-282c6-6 16-9 24-7 9 3 16 10 18 18 3 9 0 18-6 25L518 718c-5 4-11 7-18 7" />
    </svg>
  );
}

export default SvgChevronDown;
