import * as React from "react";

function SvgCalendar(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M19.313 3.563h-1.688V1.875H16.5v1.688h-9V1.875H6.375v1.688H4.688a2.816 2.816 0 00-2.813 2.813v12.938a2.816 2.816 0 002.813 2.813h14.625a2.816 2.816 0 002.813-2.813V6.375a2.817 2.817 0 00-2.813-2.812zM21 19.313A1.69 1.69 0 0119.313 21H4.688A1.69 1.69 0 013 19.313V9.188h18v10.125zm0-11.25H3V6.375a1.69 1.69 0 011.688-1.688h1.688v1.125H7.5V4.688h9v1.125h1.125V4.688h1.688c.93 0 1.687.756 1.687 1.687v1.688z" />
    </svg>
  );
}

export default SvgCalendar;
