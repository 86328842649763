import * as React from "react";

function SvgPlus(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 16 16" {...props}>
      <path d="M9 7V4L8 3 7 4v3H4L3 8l1 1h3v3l1 1 1-1V9h3l1-1-1-1z" />
    </svg>
  );
}

export default SvgPlus;
