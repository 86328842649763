import * as React from "react";

const AddDocumentSvg = (props) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect width="100" height="100" fill="white"/>
<path d="M33.6417 9.17623C40.0854 11.6738 44.7717 20.4155 51.3828 21.7778C60.086 23.5943 66.3624 12.6956 74.6471 12.128C83.0156 11.5603 91.2167 20.529 94.313 30.5195C97.6604 41.4181 96.3214 54.3603 91.5514 64.2372C86.7814 74.1141 78.9987 80.9258 70.6303 83.8775C60.7555 87.3969 50.0439 85.3534 40.9223 79.1094C31.1312 72.5247 27.2817 57.1985 17.909 51.6356C12.6369 48.4569 6.6116 44.7104 5.35633 37.1041C4.60317 32.2224 6.19317 27.2272 8.62003 23.4807C13.9758 15.7608 25.608 6.11098 33.6417 9.17623Z" fill="#E5F8F3"/>
<path d="M78.2002 26.4929V63.9329C78.2002 67.3649 75.4073 70.1729 71.9938 70.1729H22.3422C18.9286 70.1729 16.1357 67.3649 16.1357 63.9329V26.4929C16.1357 23.0609 18.9286 20.2529 22.3422 20.2529H71.9938C75.4073 20.2529 78.2002 23.0609 78.2002 26.4929Z" fill="#FFBC80"/>
<mask id="mask0_229_4051" style={{'mask-type':'alpha'}} maskUnits="userSpaceOnUse" x="16" y="20" width="63" height="51">
<path d="M78.2002 26.4929V63.9329C78.2002 67.3649 75.4073 70.1729 71.9938 70.1729H22.3422C18.9286 70.1729 16.1357 67.3649 16.1357 63.9329V26.4929C16.1357 23.0609 18.9286 20.2529 22.3422 20.2529H71.9938C75.4073 20.2529 78.2002 23.0609 78.2002 26.4929Z" fill="#FFBC80"/>
</mask>
<g mask="url(#mask0_229_4051)">
<rect x="11.7139" y="46.1216" width="72.6926" height="27.6098" fill="#ABD354"/>
</g>
<path d="M26.7093 28.9892V28.3652C26.7093 28.0532 26.7093 28.0532 27.0196 28.0532V27.7412H26.7093V27.4292C26.7093 27.1172 26.3989 27.1172 26.3989 27.1172H25.468C25.468 27.1172 25.468 26.4932 25.1576 26.4932H24.2267C24.2267 26.4932 23.9164 26.4932 23.9164 26.8052V27.1172H22.9854C22.6751 27.1172 22.6751 27.1172 22.6751 27.4292V27.7412H22.3647V28.0532C22.6751 28.0532 22.6751 28.0532 22.6751 28.3652V28.9892C22.6751 29.3012 22.9854 29.3012 22.9854 29.3012H23.2957V31.1732H22.9854C22.6751 31.1732 22.6751 31.1732 22.6751 31.4852V31.7972H22.3647V32.1092H22.6751V32.4212C22.6751 32.4212 22.6751 32.7332 22.9854 32.7332H26.3989C26.3989 32.7332 26.7093 32.7332 26.7093 32.4212V32.1092H27.0196V31.7972H26.7093V31.4852V31.1732H26.0886V29.3012H26.3989C26.3989 29.3012 26.7093 29.3012 26.7093 28.9892Z" fill="#1B2D93"/>
<path d="M46.0015 42.6766V46.2031H65.6238V42.6766H46.0015Z" fill="white"/>
<path d="M46.0015 49.0027V52.5293H65.6238V49.0027H46.0015Z" fill="white"/>
<path d="M32.2949 27.8279V31.4907H50.9143V27.8279H32.2949Z" fill="#FF7A00"/>
<path d="M64.9292 26.511V32.751H71.9623V26.511H64.9292Z" fill="#FF7A00"/>
<path d="M32.6053 52.0768C35.3475 52.0768 37.5705 49.8418 37.5705 47.0848C37.5705 44.3278 35.3475 42.0928 32.6053 42.0928C29.8631 42.0928 27.6401 44.3278 27.6401 47.0848C27.6401 49.8418 29.8631 52.0768 32.6053 52.0768Z" fill="#1B2D93"/>
<path d="M22.3647 63.9332V62.3732C22.3647 57.6932 26.7093 53.9492 32.2951 53.9492C37.8809 53.9492 42.2254 57.6932 42.2254 62.3732V63.9332H22.3647Z" fill="#1B2D93"/>
</svg>

);

export default AddDocumentSvg;
