import * as React from "react"

const Info = (props) => (
  <svg
    {...props}
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.669 6.669 0 0 0 8 14.666 6.664 6.664 0 0 0 14.667 8c0-3.68-2.98-6.667-6.667-6.667Z"
      fill="#FFF5F5"
    />
    <path
      d="M8 .833A7.17 7.17 0 0 0 .833 8h1A6.17 6.17 0 0 1 8 1.833v-1ZM.833 8A7.169 7.169 0 0 0 8 15.166v-1A6.169 6.169 0 0 1 1.833 8h-1ZM8 15.166A7.164 7.164 0 0 0 15.167 8h-1A6.164 6.164 0 0 1 8 14.166v1ZM15.167 8A7.165 7.165 0 0 0 8 .833v1A6.165 6.165 0 0 1 14.167 8h1ZM7.993 4.887c.327 0 .587.266.587.586 0 .32-.26.58-.58.58a.582.582 0 0 1-.587-.58c0-.32.26-.586.58-.586ZM8.587 10.534c0 .32-.267.587-.587.587a.587.587 0 0 1-.58-.587V7.587c0-.32.26-.58.58-.58.32 0 .587.26.587.58v2.947Z"
      fill="#FB5247"
    />
  </svg>
)

export default Info
