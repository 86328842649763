import * as React from "react";

function SvgWrong(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 1080 1080" {...props}>
      <path d="M646.773 540l280.722-280.722c29.484-29.484 29.484-77.289 0-106.773s-77.289-29.484-106.773 0L540 433.228 259.278 152.506c-29.484-29.484-77.289-29.484-106.773 0s-29.484 77.289 0 106.773L433.227 540 152.506 820.722c-29.484 29.484-29.484 77.288 0 106.772s77.288 29.484 106.772 0L540 646.773l280.722 280.721c29.484 29.484 77.288 29.484 106.772 0s29.484-77.288 0-106.772L646.773 540z" />
    </svg>
  );
}

export default SvgWrong;
