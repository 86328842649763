import * as React from "react";

const AddDocumentSvg = (props) => (
<svg width="206" height="157" viewBox="0 0 206 157" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_673_15637)">
<rect width="206" height="157" fill="white"/>
<path d="M142.148 142.519H97.5146C93.4232 142.519 90.0757 139.186 90.0757 135.112V75.8518C90.0757 71.7777 93.4232 68.4443 97.5146 68.4443H142.148C146.239 68.4443 149.587 71.7777 149.587 75.8518V135.112C149.587 139.186 146.239 142.519 142.148 142.519Z" fill="#FFC37D"/>
<path d="M149.587 120.297V135.112C149.587 139.186 146.239 142.519 142.148 142.519H97.5146C93.4232 142.519 90.0757 139.186 90.0757 135.112V120.297H149.587Z" fill="#ABD354"/>
<path d="M142.148 127.704H97.5146V135.112H142.148V127.704Z" fill="#1B2D93"/>
<path d="M101.606 127.704H97.5146V135.112H101.606V127.704Z" fill="#1B2D93"/>
<path d="M131.734 101.038C134.609 101.038 136.941 98.716 136.941 95.8522C136.941 92.9885 134.609 90.667 131.734 90.667C128.858 90.667 126.526 92.9885 126.526 95.8522C126.526 98.716 128.858 101.038 131.734 101.038Z" fill="#1B2D93"/>
<path d="M121.319 112.889V111.037C121.319 106.222 125.782 102.519 131.734 102.519C137.313 102.519 142.148 106.222 142.148 111.037V112.889H121.319Z" fill="#1B2D93"/>
<path d="M97.678 105.712V113.119H112.556V105.712H97.678Z" fill="white"/>
<path d="M97.3677 94.5085V101.916H119.684V94.5085H97.3677Z" fill="white"/>
<path d="M108.301 127.704H103.466V135.112H108.301V127.704Z" fill="#1B2D93"/>
<path d="M111.648 127.704H110.161V135.112H111.648V127.704Z" fill="#1B2D93"/>
<path d="M126.898 127.704H120.575V135.112H126.898V127.704Z" fill="#1B2D93"/>
<path d="M131.733 127.704H128.386V135.112H131.733V127.704Z" fill="#1B2D93"/>
<path d="M135.081 127.704H133.221V135.112H135.081V127.704Z" fill="#1B2D93"/>
<path d="M138.801 127.704H136.569V135.112H138.801V127.704Z" fill="#1B2D93"/>
<path d="M142.148 127.704H140.66V135.112H142.148V127.704Z" fill="#1B2D93"/>
<path d="M122.063 78.8146V78.0738C122.063 77.7034 122.063 77.7034 122.435 77.7034V77.3331H122.063V76.5923C122.063 76.2219 121.691 76.2219 121.691 76.2219H120.575C120.575 76.2219 120.575 75.8516 120.203 75.8516H119.087C119.087 75.8516 118.715 75.8516 118.715 76.2219V76.5923H117.6C117.228 76.5923 117.228 76.5923 117.228 76.9627V77.3331H116.856V77.7034C117.228 77.7034 117.228 77.7034 117.228 78.0738V78.8146C117.228 79.1849 117.6 79.1849 117.6 79.1849H117.972V81.4072H117.6C117.228 81.4072 117.228 81.4072 117.228 81.7776V82.1479H116.856V82.5183H117.228V82.8887C117.228 82.8887 117.228 83.2591 117.6 83.2591H121.691C121.691 83.2591 122.063 83.2591 122.063 82.8887V82.5183H122.435V82.1479H122.063V81.7776C122.063 81.7776 122.063 81.4072 121.691 81.4072H121.319V79.5553H121.691C121.691 79.5553 122.063 79.1849 122.063 78.8146Z" fill="#1B2D93"/>
<path d="M118.716 127.704H113.508V135.112H118.716V127.704Z" fill="#1B2D93"/>
<path d="M109.255 97.7891V137.106C109.255 140.71 106.294 143.659 102.675 143.659H50.0303C46.411 143.659 43.4497 140.71 43.4497 137.106V97.7891C43.4497 94.1851 46.411 91.2363 50.0303 91.2363H102.675C106.294 91.2363 109.255 94.1851 109.255 97.7891Z" fill="#B5CDFF"/>
<path d="M52.9207 110.893C52.2594 110.893 51.7757 111.119 51.45 111.492V111.03H49.9693V117.911H51.45V115.483C51.7757 115.856 52.2594 116.082 52.9207 116.082C54.2138 116.082 55.2798 114.952 55.2798 113.487C55.2798 112.023 54.2138 110.893 52.9207 110.893ZM52.6246 114.687C51.9435 114.687 51.45 114.225 51.45 113.487C51.45 112.75 51.9435 112.288 52.6246 112.288C53.3057 112.288 53.7992 112.75 53.7992 113.487C53.7992 114.225 53.3057 114.687 52.6246 114.687Z" fill="#1B2D93"/>
<path d="M59.7024 111.03V111.492C59.3767 111.119 58.893 110.893 58.2317 110.893C56.9386 110.893 55.8726 112.023 55.8726 113.487C55.8726 114.952 56.9386 116.082 58.2317 116.082C58.893 116.082 59.3767 115.856 59.7024 115.483V115.945H61.1831V111.03H59.7024ZM58.5278 114.687C57.8467 114.687 57.3532 114.225 57.3532 113.487C57.3532 112.75 57.8467 112.288 58.5278 112.288C59.2089 112.288 59.7024 112.75 59.7024 113.487C59.7024 114.225 59.2089 114.687 58.5278 114.687Z" fill="#1B2D93"/>
<path d="M65.1615 110.893C64.51 110.893 64.0165 111.128 63.7499 111.492V111.03H62.2693V115.945H63.7499V113.261C63.7499 112.564 64.125 112.249 64.6679 112.249C65.1417 112.249 65.5267 112.534 65.5267 113.143V115.945H67.0073V112.927C67.0073 111.6 66.1584 110.893 65.1615 110.893Z" fill="#1B2D93"/>
<path d="M52.3384 125.912C53.3058 125.912 54.1448 125.41 54.5692 124.644L53.2762 123.906C53.1182 124.25 52.753 124.457 52.3187 124.457C51.6771 124.457 51.2033 123.995 51.2033 123.317C51.2033 122.638 51.6771 122.176 52.3187 122.176C52.753 122.176 53.1084 122.383 53.2762 122.727L54.5692 121.98C54.1448 121.223 53.2959 120.722 52.3384 120.722C50.8381 120.722 49.7227 121.852 49.7227 123.317C49.7227 124.781 50.8381 125.912 52.3384 125.912Z" fill="#1B2D93"/>
<path d="M58.6518 120.859V121.321C58.3261 120.948 57.8424 120.722 57.1811 120.722C55.888 120.722 54.8219 121.852 54.8219 123.317C54.8219 124.781 55.888 125.912 57.1811 125.912C57.8424 125.912 58.3261 125.686 58.6518 125.312V125.774H60.1325V120.859H58.6518ZM57.4772 124.516C56.7961 124.516 56.3026 124.054 56.3026 123.317C56.3026 122.579 56.7961 122.118 57.4772 122.118C58.1583 122.118 58.6518 122.579 58.6518 123.317C58.6518 124.054 58.1583 124.516 57.4772 124.516Z" fill="#1B2D93"/>
<path d="M62.6993 121.734V120.859H61.2187V125.774H62.6993V123.553C62.6993 122.579 63.568 122.334 64.18 122.432V120.761C63.5581 120.761 62.8967 121.076 62.6993 121.734Z" fill="#1B2D93"/>
<path d="M68.3106 118.894V121.321C67.9849 120.948 67.5012 120.722 66.8398 120.722C65.5468 120.722 64.4807 121.852 64.4807 123.317C64.4807 124.781 65.5468 125.912 66.8398 125.912C67.5012 125.912 67.9849 125.686 68.3106 125.312V125.774H69.7912V118.894H68.3106ZM67.136 124.516C66.4549 124.516 65.9613 124.054 65.9613 123.317C65.9613 122.579 66.4549 122.118 67.136 122.118C67.8171 122.118 68.3106 122.579 68.3106 123.317C68.3106 124.054 67.8171 124.516 67.136 124.516Z" fill="#1B2D93"/>
<path d="M78.3266 100.41V99.7549C78.3266 99.4273 78.3266 99.4273 78.6556 99.4273V99.0996H78.3266V98.772C78.3266 98.4443 77.9976 98.4443 77.9976 98.4443H77.0105C77.0105 98.4443 77.0105 97.7891 76.6815 97.7891H75.6944C75.6944 97.7891 75.3654 97.7891 75.3654 98.1167V98.4443H74.3783C74.0492 98.4443 74.0492 98.4443 74.0492 98.772V99.0996H73.7202V99.4273C74.0492 99.4273 74.0492 99.4273 74.0492 99.7549V100.41C74.0492 100.738 74.3783 100.738 74.3783 100.738H74.7073V102.704H74.3783C74.0492 102.704 74.0492 102.704 74.0492 103.031V103.359H73.7202V103.687H74.0492V104.014C74.0492 104.014 74.0492 104.342 74.3783 104.342H77.9976C77.9976 104.342 78.3266 104.342 78.3266 104.014V103.687H78.6556V103.359H78.3266V103.031V102.704H77.6685V100.738H77.9976C77.9976 100.738 78.3266 100.738 78.3266 100.41Z" fill="#568AF7"/>
<path d="M50.054 130.511V137.063H76.3762V130.511H50.054Z" fill="#FF9518"/>
<path d="M50.0264 97.8378V104.391H69.768V97.8378H50.0264Z" fill="#568AF7"/>
<path d="M82.8994 97.8085V104.361H102.641V97.8085H82.8994Z" fill="#568AF7"/>
<path d="M92.1458 124.655C95.0533 124.655 97.4102 122.308 97.4102 119.413C97.4102 116.518 95.0533 114.171 92.1458 114.171C89.2383 114.171 86.8813 116.518 86.8813 119.413C86.8813 122.308 89.2383 124.655 92.1458 124.655Z" fill="white"/>
<path d="M81.2878 137.106V135.467C81.2878 130.553 85.8942 126.621 91.8167 126.621C97.7392 126.621 102.346 130.553 102.346 135.467V137.106H81.2878Z" fill="white"/>
<path d="M149.439 79.5331C157.737 79.5331 164.465 72.834 164.465 64.5703C164.465 56.3065 157.737 49.6074 149.439 49.6074C141.14 49.6074 134.412 56.3065 134.412 64.5703C134.412 72.834 141.14 79.5331 149.439 79.5331Z" fill="#00BA88"/>
<path d="M156.625 62.621H151.399V57.4165C151.399 56.8989 151.192 56.4025 150.825 56.0365C150.457 55.6705 149.959 55.4648 149.439 55.4648C148.919 55.4648 148.421 55.6705 148.053 56.0365C147.685 56.4025 147.479 56.8989 147.479 57.4165V62.621H142.252C141.733 62.621 141.234 62.8266 140.867 63.1926C140.499 63.5586 140.292 64.055 140.292 64.5727C140.292 65.0903 140.499 65.5867 140.867 65.9527C141.234 66.3187 141.733 66.5243 142.252 66.5243H147.479V71.7288C147.479 72.2464 147.685 72.7428 148.053 73.1089C148.421 73.4749 148.919 73.6805 149.439 73.6805C149.959 73.6805 150.457 73.4749 150.825 73.1089C151.192 72.7428 151.399 72.2464 151.399 71.7288V66.5243H156.625C157.145 66.5243 157.644 66.3187 158.011 65.9527C158.379 65.5867 158.585 65.0903 158.585 64.5727C158.585 64.055 158.379 63.5586 158.011 63.1926C157.644 62.8266 157.145 62.621 156.625 62.621Z" fill="white"/>
<rect width="160" height="160" transform="translate(23 -1)" fill="white"/>
<path d="M47.33 103.106C43.4876 94.3693 43.4619 84.4623 45.1354 75.136C46.5449 67.158 49.9349 58.745 57.4348 54.8196C65.5361 50.6068 74.3395 54.9829 82.9185 55.0324C89.341 55.0894 95.9952 53.6714 101.276 50.039C106.431 46.4387 109.849 41.0533 114.398 36.6626C124.616 26.7201 139.169 29.5704 147.634 40.304C154.123 48.5717 158.783 63.1704 160.602 73.4991C162.763 85.9665 159.918 99.4341 152.381 109.573C139.67 126.42 121.189 128.544 101.913 126.218C83.0488 123.921 56.2333 123.777 47.33 103.106Z" fill="#E9F3FF"/>
<path d="M81.0177 97.9197C80.9259 98.475 80.7802 99.0114 80.5837 99.5244L82.3461 101.103C82.099 101.617 81.8175 102.111 81.4928 102.575L79.23 101.854C78.8732 102.285 78.4735 102.68 78.0373 103.033L78.7668 105.269C78.2973 105.59 77.7975 105.868 77.2774 106.113L75.6806 104.371C75.1616 104.565 74.6195 104.709 74.0572 104.8L73.5546 107.101C73.2703 107.123 72.985 107.144 72.6945 107.144C72.4041 107.144 72.1187 107.123 71.8339 107.101L71.3313 104.8C70.7695 104.709 70.2269 104.565 69.7079 104.371L68.1111 106.113C67.591 105.869 67.0912 105.59 66.6217 105.269L67.3512 103.033C66.915 102.68 66.5159 102.285 66.159 101.854L63.8963 102.575C63.5715 102.111 63.2907 101.617 63.043 101.103L64.8053 99.5244C64.6089 99.0114 64.4631 98.4755 64.3714 97.9197L62.0433 97.4228C62.0208 97.1412 62 96.8591 62 96.572C62 96.2849 62.0208 96.0028 62.0433 95.7212L64.3714 95.2244C64.4631 94.669 64.6089 94.1327 64.8053 93.6196L63.043 92.0411C63.2901 91.5269 63.5715 91.0328 63.8963 90.5688L66.159 91.2899C66.5159 90.8587 66.9155 90.4642 67.3518 90.1114L66.6223 87.8746C67.0917 87.5535 67.5916 87.2759 68.1116 87.031L69.7085 88.7732C70.2275 88.579 70.7695 88.4349 71.3318 88.3442L71.8345 86.0428C72.1187 86.0206 72.4041 86 72.6945 86C72.985 86 73.2703 86.0206 73.5552 86.0428L74.0578 88.3442C74.6195 88.4349 75.1622 88.579 75.6811 88.7732L77.278 87.031C77.7981 87.2753 78.2979 87.5535 78.7673 87.8746L78.0379 90.1114C78.4741 90.4642 78.8732 90.8592 79.23 91.2905L81.4928 90.5693C81.8175 91.0334 82.0984 91.5275 82.3461 92.0416L80.5837 93.6202C80.7802 94.1332 80.9259 94.669 81.0177 95.2249L83.3457 95.7218C83.3682 96.0028 83.3891 96.2849 83.3891 96.572C83.3891 96.8591 83.3682 97.1412 83.3457 97.4228L81.0177 97.9197ZM72.6945 91.0078C69.5858 91.0078 67.0658 93.4989 67.0658 96.572C67.0658 99.6451 69.5858 102.136 72.6945 102.136C75.8033 102.136 78.3232 99.6451 78.3232 96.572C78.3232 93.4989 75.8033 91.0078 72.6945 91.0078Z" fill="#B5CDFF"/>
<path d="M98.1831 45.387C100.113 42.084 104.887 42.084 106.817 45.387L134.044 91.9772C135.992 95.3105 133.588 99.5 129.727 99.5H75.2731C71.4124 99.5 69.0083 95.3105 70.9562 91.9772L98.1831 45.387Z" fill="#FF706E"/>
<path d="M105.987 70.0001C105.987 73.1376 105.987 76.2751 105.987 79.4125C105.987 80.725 105.694 81 104.273 81C102.972 81 101.67 80.9875 100.368 81C99.4782 81 99 80.6125 99 79.7625C99 73.2626 99 66.7502 99 60.2502C99 59.4002 99.4516 59.0003 100.355 59.0003C101.763 59.0128 103.184 59.0128 104.592 59.0003C105.562 58.9878 106 59.4377 106 60.3377C105.973 63.5502 105.987 66.7752 105.987 70.0001Z" fill="white"/>
<path d="M102.447 90.9998C101.722 90.9998 100.997 90.9998 100.285 90.9998C99.4812 90.9998 99.033 90.6369 99.0198 89.968C98.9934 88.664 98.9934 87.3715 99.0198 86.0676C99.033 85.3873 99.5075 85.0244 100.272 85.0131C101.735 84.9904 103.212 85.0018 104.675 85.0131C105.479 85.0244 105.967 85.3873 105.98 86.0902C106.007 87.3715 106.007 88.6527 105.98 89.9339C105.967 90.6256 105.519 91.0111 104.688 90.9998C103.937 90.9998 103.186 90.9998 102.447 90.9998Z" fill="white"/>
<path d="M142.24 65.6716C142.181 66.029 142.087 66.3742 141.961 66.7044L143.095 67.7204C142.936 68.0513 142.755 68.3693 142.546 68.668L141.089 68.2039C140.86 68.4814 140.603 68.7353 140.322 68.9624L140.791 70.402C140.489 70.6086 140.167 70.7873 139.833 70.9449L138.805 69.8236C138.471 69.9486 138.122 70.0414 137.76 70.0997L137.437 71.5809C137.254 71.5952 137.07 71.6085 136.883 71.6085C136.696 71.6085 136.513 71.5952 136.329 71.5809L136.006 70.0997C135.644 70.0414 135.295 69.9486 134.961 69.8236L133.933 70.9449C133.598 70.7877 133.277 70.6086 132.975 70.402L133.444 68.9624C133.163 68.7353 132.906 68.4811 132.677 68.2039L131.22 68.668C131.011 68.3693 130.831 68.0513 130.671 67.7204L131.806 66.7044C131.679 66.3742 131.585 66.0294 131.526 65.6716L130.028 65.3518C130.013 65.1706 130 64.989 130 64.8042C130 64.6195 130.013 64.4379 130.028 64.2567L131.526 63.9369C131.585 63.5795 131.679 63.2343 131.806 62.9041L130.671 61.8881C130.83 61.5572 131.011 61.2392 131.22 60.9405L132.677 61.4046C132.906 61.1271 133.164 60.8732 133.444 60.6461L132.975 59.2065C133.277 58.9999 133.599 58.8212 133.934 58.6636L134.961 59.7849C135.295 59.6599 135.644 59.5671 136.006 59.5088L136.33 58.0276C136.513 58.0133 136.696 58 136.883 58C137.07 58 137.254 58.0133 137.437 58.0276L137.761 59.5088C138.122 59.5671 138.471 59.6599 138.805 59.7849L139.833 58.6636C140.168 58.8208 140.489 58.9999 140.792 59.2065L140.322 60.6461C140.603 60.8732 140.86 61.1274 141.089 61.405L142.546 60.9409C142.755 61.2395 142.936 61.5575 143.095 61.8885L141.961 62.9044C142.087 63.2346 142.181 63.5795 142.24 63.9372L143.738 64.257C143.753 64.4379 143.766 64.6195 143.766 64.8042C143.766 64.989 143.753 65.1706 143.738 65.3518L142.24 65.6716ZM136.883 61.2231C134.882 61.2231 133.26 62.8264 133.26 64.8042C133.26 66.7821 134.882 68.3854 136.883 68.3854C138.884 68.3854 140.506 66.7821 140.506 64.8042C140.506 62.8264 138.884 61.2231 136.883 61.2231Z" fill="#B5CDFF"/>
<path d="M58.1354 80.314C59.3148 80.314 60.2709 79.3483 60.2709 78.157C60.2709 76.9657 59.3148 76 58.1354 76C56.9561 76 56 76.9657 56 78.157C56 79.3483 56.9561 80.314 58.1354 80.314Z" fill="#FFC401"/>
<path d="M120.135 111.314C121.315 111.314 122.271 110.348 122.271 109.157C122.271 107.966 121.315 107 120.135 107C118.956 107 118 107.966 118 109.157C118 110.348 118.956 111.314 120.135 111.314Z" fill="#FFC401"/>
<path d="M126 50C128.209 50 130 48.2091 130 46C130 43.7909 128.209 42 126 42C123.791 42 122 43.7909 122 46C122 48.2091 123.791 50 126 50Z" fill="#FFC401"/>
<path d="M74.7237 65.2764C74.3552 64.9079 73.7576 64.9079 73.389 65.2764L72.5001 66.1653L71.6111 65.2764C71.2426 64.9079 70.6451 64.9079 70.2765 65.2764C69.9078 65.6449 69.9078 66.2424 70.2765 66.611L71.1654 67.5L70.2765 68.3889C69.9078 68.7574 69.9078 69.355 70.2765 69.7236C70.4608 69.9079 70.7023 70 70.9437 70C71.1852 70 71.4268 69.9078 71.611 69.7236L72.4999 68.8346L73.3889 69.7236C73.5732 69.9079 73.8147 70 74.0561 70C74.2976 70 74.5392 69.9078 74.7234 69.7236C75.092 69.3551 75.092 68.7576 74.7234 68.3889L73.8345 67.5L74.7234 66.611C75.0922 66.2424 75.0922 65.6449 74.7237 65.2764Z" fill="#FB5247"/>
<path d="M148.513 79.5622C147.771 78.8126 146.568 78.8126 145.825 79.5622L144.035 81.3706L142.245 79.5622C141.503 78.8126 140.299 78.8126 139.557 79.5622C138.814 80.3119 138.814 81.5274 139.557 82.2773L141.347 84.0857L139.557 85.8941C138.814 86.6437 138.814 87.8592 139.557 88.6091C139.928 88.9841 140.414 89.1714 140.901 89.1714C141.387 89.1714 141.874 88.9838 142.244 88.6091L144.035 86.8007L145.825 88.6091C146.196 88.9841 146.683 89.1714 147.169 89.1714C147.655 89.1714 148.142 88.9838 148.513 88.6091C149.255 87.8595 149.255 86.644 148.513 85.8941L146.722 84.0857L148.513 82.2773C149.255 81.5274 149.255 80.3119 148.513 79.5622Z" fill="#FB5247"/>
<mask id="mask0_673_15637" style={{'mask-type':'alpha'}} maskUnits="userSpaceOnUse" x="70" y="42" width="65" height="58">
<path d="M98.1831 45.387C100.113 42.084 104.887 42.084 106.817 45.387L134.044 91.9772C135.992 95.3105 133.588 99.5 129.727 99.5H75.2731C71.4124 99.5 69.0083 95.3105 70.9562 91.9772L98.1831 45.387Z" fill="#FF706F"/>
</mask>
<g mask="url(#mask0_673_15637)">
<path d="M130.845 82.7344C129.707 85.579 127.396 87.8623 126.099 90.6809C124.876 93.3404 123.476 95.9892 122.334 98.6766C121.83 99.8632 121.574 101.635 121.574 102.932C121.574 103.516 122.274 102.103 122.285 102.086C123.632 99.9807 124.616 97.7203 125.817 95.5372C127.725 92.0702 129.578 88.5362 132.035 85.42C132.148 85.2756 134.121 82.3648 134.156 83.8871C134.305 90.4592 127.474 94.762 126.884 101.252C126.736 102.878 127.766 101.633 128.245 100.908C129.855 98.4765 129.987 95.0803 131.949 92.9006C132.502 92.2858 133.224 91.5979 134.107 91.5639C136.244 91.4817 133.119 96.6598 132.697 97.438C132.327 98.1199 129.099 103.337 131.949 101.595C134.366 100.118 133.676 95.5565 136.363 94.2127" stroke="#FB5247" strokeLinecap="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_673_15637">
<rect width="206" height="157" fill="white"/>
</clipPath>
</defs>
</svg>


);

export default AddDocumentSvg;
