import React from "react";

const SvgResend = (props) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
    <path
      d="M13.728 7.33606C15.576 9.66406 15.432 12.9761 13.488 15.1841C11.664 17.2481 8.61601 17.7281 6.21601 16.5041C0.912009 13.8401 2.61601 5.70406 8.40001 5.08006C9.91201 4.91206 9.93601 2.51206 8.40001 2.68006C4.60801 3.06406 1.48801 5.68006 0.696008 9.47206C-0.0719916 13.1921 1.92001 17.0081 5.28001 18.6881C8.68801 20.3921 12.912 19.4561 15.384 16.6241C18.168 13.4561 17.952 8.84806 15.408 5.60806C14.472 4.43206 12.792 6.13606 13.728 7.33606Z"
      fill="#FF9518"
    />
    <path
      d="M10.824 3.20806C9.72001 3.85606 8.61601 4.48006 7.51201 5.12806C8.11201 5.46406 8.71201 5.82406 9.31201 6.16006C9.31201 4.64806 9.31201 3.13606 9.31201 1.62406C8.71201 1.96006 8.11201 2.32006 7.51201 2.65606C8.83201 3.42406 10.128 4.16806 11.448 4.93606C11.448 4.24006 11.448 3.54406 11.448 2.87206C11.232 2.96806 11.016 3.08806 10.824 3.20806C9.48001 3.97606 10.704 6.06406 12.024 5.27206C12.24 5.15206 12.432 5.03206 12.648 4.91206C13.416 4.45606 13.416 3.28006 12.648 2.84806C11.328 2.08006 10.032 1.33606 8.71201 0.56806C7.92001 0.11206 6.91201 0.68806 6.91201 1.60006C6.91201 3.11206 6.91201 4.62406 6.91201 6.13606C6.91201 7.04806 7.92001 7.62406 8.71201 7.16806C9.81601 6.52006 10.92 5.89606 12.024 5.24806C13.368 4.50406 12.168 2.44006 10.824 3.20806Z"
      fill="#FF9518"
    />
  </svg>
);

export default SvgResend;
