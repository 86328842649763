import * as React from "react"

const UploadDoc = (props) => (
  <svg
    {...props}
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4.807 4.14 1.526-1.534v6.393a.667.667 0 1 0 1.334 0V2.606l1.527 1.533a.667.667 0 0 0 1.092-.217.668.668 0 0 0-.146-.73L7.473.527a.667.667 0 0 0-.22-.14.667.667 0 0 0-.506 0 .667.667 0 0 0-.22.14L3.86 3.193a.67.67 0 0 0 .947.946ZM13 7a.667.667 0 0 0-.666.666v4a.667.667 0 0 1-.667.667H2.333a.667.667 0 0 1-.666-.667v-4a.667.667 0 0 0-1.334 0v4a2 2 0 0 0 2 2h9.334a2 2 0 0 0 2-2v-4A.667.667 0 0 0 13 6.999Z"
      fill="#1B2D93"
    />
  </svg>
)

export default UploadDoc
