import React from "react";

const SvgPpeLogo = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.1019 5.33325H3.89806C3.57217 5.33325 3.29404 5.56886 3.24046 5.89032L2.12935 12.557C2.06163 12.9633 2.37499 13.3333 2.78695 13.3333H13.213C13.625 13.3333 13.9383 12.9633 13.8706 12.557L12.7595 5.89032C12.7059 5.56886 12.4278 5.33325 12.1019 5.33325Z" stroke="#F1A13F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.33325 5.33341C5.33325 3.86066 6.52716 2.66675 7.99992 2.66675C9.47268 2.66675 10.6666 3.86066 10.6666 5.33341" stroke="#F1A13F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);


export default SvgPpeLogo;