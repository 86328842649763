import * as React from "react"

const StartedEarningLogo = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="white"/>
    <mask id="mask0_275_1294" style={{'maskType':'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
      <circle cx="16" cy="16" r="16" fill="white"/>
    </mask>
    <g mask="url(#mask0_275_1294)">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.9662 14.041V30.8422L23.0935 31.7078H8.22054C7.87793 31.3702 7.68814 31.1798 7.34766 30.8422V11.8114C7.34766 10.9167 8.07296 10.1914 8.96766 10.1914H20.084L23.9662 14.041Z" fill="#276EF1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.965 14.0235H20.0498V10.1904L23.965 14.0235Z" fill="#0038C0"/>
      <path d="M20.447 20.7842C20.7976 20.7842 21.0819 21.0684 21.0819 21.4191C21.0819 21.7698 20.7976 22.054 20.447 22.054H11.8244C11.4737 22.054 11.1895 21.7698 11.1895 21.4191C11.1895 21.0684 11.4737 20.7842 11.8244 20.7842H20.447Z" fill="#EEF4FE"/>
      <path d="M20.447 22.7949C20.7976 22.7949 21.0819 23.0792 21.0819 23.4298C21.0819 23.7805 20.7976 24.0648 20.447 24.0648H11.8244C11.4737 24.0648 11.1895 23.7805 11.1895 23.4298C11.1895 23.0792 11.4737 22.7949 11.8244 22.7949H20.447Z" fill="#EEF4FE"/>
      <path d="M20.447 24.9277C20.7976 24.9277 21.0819 25.212 21.0819 25.5627C21.0819 25.9133 20.7976 26.1976 20.447 26.1976H11.8244C11.4737 26.1976 11.1895 25.9133 11.1895 25.5627C11.1895 25.212 11.4737 24.9277 11.8244 24.9277H20.447Z" fill="#EEF4FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.292 8.0689C21.5683 8.0236 21.8519 8 22.141 8C25.017 8 27.3484 10.3315 27.3484 13.2075C27.3484 16.0835 25.017 18.415 22.141 18.415C20.9457 18.415 19.8445 18.0123 18.9656 17.3352C18.9522 17.3256 18.9389 17.3157 18.9258 17.3054C17.7127 16.352 16.9336 14.8714 16.9336 13.2088C16.9336 10.6279 18.811 8.4857 21.2746 8.07307C21.2809 8.07201 21.2872 8.07102 21.2935 8.07008C21.293 8.06969 21.2925 8.06929 21.292 8.0689Z" fill="#09B885"/>
      <path d="M21.2519 14.2838C21.1156 14.428 20.8861 14.428 20.7497 14.2838L20.1222 13.6203C19.9859 13.4761 19.7564 13.4761 19.6201 13.6203L19.4748 13.7739C19.3487 13.9072 19.3488 14.1159 19.475 14.2491L20.5124 15.3439C20.7852 15.6318 21.2438 15.6316 21.5163 15.3434L24.9988 11.6609C25.1248 11.5277 25.1248 11.3193 24.9988 11.186L24.8412 11.0194C24.7049 10.8752 24.4754 10.8752 24.339 11.0194L21.2519 14.2838Z" fill="white"/>
    </g>
  </svg >
)

export default StartedEarningLogo
