import * as React from "react";

function SvgRight(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 100 100" {...props}>
      <path d="M87.6 30.8L42.7 77.5c-2.5 2.6-6.5 2.6-9 0L11.3 54c-2.5-2.6-2.5-6.8 0-9.4 2.5-2.6 6.5-2.6 9 0l18 18.8 40.4-42c2.5-2.6 6.5-2.6 9 0 2.4 2.6 2.4 6.8-.1 9.4z" />
    </svg>
  );
}

export default SvgRight;
