import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={28}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={28} height={16} rx={4} fill="#7460E4" />
    <path
      d="M10.42 3.89V11H9.196L6.007 5.907V11H4.78V3.89h1.226l3.198 5.103V3.891h1.216Zm3.673 7.208c-.39 0-.744-.064-1.06-.19-.312-.131-.58-.312-.8-.543a2.402 2.402 0 0 1-.504-.815 2.855 2.855 0 0 1-.175-1.01v-.196c0-.414.06-.788.18-1.123a2.55 2.55 0 0 1 .503-.86c.215-.24.47-.424.762-.551.293-.127.61-.19.952-.19.378 0 .708.063.991.19.284.127.518.306.704.537.188.228.328.5.42.815.094.316.141.664.141 1.045v.503h-4.082v-.845h2.92v-.093a1.653 1.653 0 0 0-.127-.595 1.013 1.013 0 0 0-.347-.45c-.156-.113-.364-.17-.625-.17-.195 0-.37.042-.522.127-.15.081-.275.2-.376.356-.101.156-.18.345-.235.566a3.182 3.182 0 0 0-.078.738v.195c0 .231.031.446.093.645.065.195.16.366.283.512.124.147.274.262.45.347.175.081.376.122.6.122.283 0 .536-.057.757-.17.221-.115.413-.276.576-.484l.62.6a2.42 2.42 0 0 1-.444.479c-.182.15-.405.272-.669.366a2.66 2.66 0 0 1-.908.142Zm4.468-1.27 1.22-4.111h.752l-.205 1.23L19.098 11h-.674l.137-1.172Zm-.718-4.111.952 4.13.078 1.153h-.752l-1.43-5.283h1.152Zm3.833 4.082.923-4.082h1.147L22.32 11h-.752l.108-1.201ZM20.66 5.717l1.206 4.062.152 1.221h-.674l-1.245-4.058-.205-1.225h.766Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
