import * as React from "react";

const AddDocumentSvg = (props) => (
  <svg width="52" height="58" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M33.091 28.4526H18.9092V47.2324H33.091V28.4526Z" fill="#FFB359"/>
<path fillRule="evenodd" clipRule="evenodd" d="M39 13.4287V23.4446C39 31.0379 33.1677 37.2164 26 37.2164C18.8323 37.2164 13 31.0379 13 23.4446V13.4287H39Z" fill="#FFC37D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M52 53.5036C52 51.4229 50.5476 49.0629 48.7494 48.2295L33.0909 40.9722C31.096 42.5371 28.6544 43.4761 26 43.4761C23.3456 43.4761 20.904 42.5371 18.9304 40.9722L3.25425 48.2295C1.45697 49.0616 0 51.4258 0 53.5036V57.2443C0 57.9378 0.5316 58.5 1.18379 58.5H50.8162C51.47 58.5 52 57.9517 52 57.2443V53.5036Z" fill="#568AF7"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13 13.4271C13 9.27914 15.9963 4.78293 19.682 3.38845L28.4342 0.0770862C29.0479 -0.15512 29.7807 0.15512 30.07 0.768056L31.9091 4.66457C35.8253 4.66457 39 8.03621 39 12.1765V19.6884C39 16.2311 36.3544 13.4285 33.0909 13.4285H18.9091C15.6456 13.4285 13 16.2311 13 19.6884V13.4271Z" fill="#050F24"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.0001 43.4761C23.3458 43.4761 20.9041 42.5371 18.9305 40.9722L18.9239 40.9752L16.0408 42.31L18.2982 48.1543C18.6008 48.9377 19.5348 49.1838 20.1486 48.6419L26.0001 43.4761L31.8517 48.642C32.4655 49.1838 33.3994 48.9377 33.702 48.1543L35.9624 42.303L33.091 40.9722C31.0961 42.5371 28.6545 43.4761 26.0001 43.4761Z" fill="white"/>
<mask id="mask0_229_4130" style={{'mask-type':'alpha'}} maskUnits="userSpaceOnUse" x="0" y="40" width="52" height="19">
<path fillRule="evenodd" clipRule="evenodd" d="M52 53.5036C52 51.4229 50.5476 49.0629 48.7494 48.2295L33.0909 40.9722C31.096 42.5371 28.6544 43.4761 26 43.4761C23.3456 43.4761 20.904 42.5371 18.9304 40.9722L3.25425 48.2295C1.45697 49.0616 0 51.4258 0 53.5036V57.2443C0 57.9378 0.5316 58.5 1.18379 58.5H50.8162C51.47 58.5 52 57.9517 52 57.2443V53.5036Z" fill="#8EA1E6"/>
</mask>
<g mask="url(#mask0_229_4130)">
<path d="M49.1267 47.1094C47.2845 48.8051 45.5764 50.6664 43.9031 52.5272C42.598 53.9786 41.3133 55.5783 40.3471 57.282C39.9026 58.0656 39.4864 58.8953 39.1885 59.7465C39.1017 59.9945 39.2346 59.9114 39.4028 59.78C40.0466 59.277 40.6036 58.6424 41.1842 58.0722C43.6826 55.6188 46.1892 53.0773 48.9794 50.9534C50.6875 49.6532 52.6895 48.2654 54.7789 47.6451C55.2351 47.5097 54.4848 48.1248 54.3905 48.2345C52.7821 50.1052 51.0399 51.8415 49.4884 53.7661C48.3377 55.1934 47.2565 56.6821 46.0863 58.0923C45.8847 58.3353 45.3899 58.748 45.3899 59.1036C45.3899 59.4294 45.2693 59.6503 45.2693 59.9474C45.2693 60.4751 46.4264 59.3961 46.4614 59.3647C48.0637 57.9278 49.3677 56.2469 51.0688 54.9046C51.7388 54.376 52.4109 53.9889 53.1717 53.6188C53.3359 53.5389 53.514 53.5234 53.5869 53.3777" stroke="#1B2D93" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>

);

export default AddDocumentSvg;
