import * as React from "react";

const AddDocumentSvg = (props) => (
  <svg
    width={206}
    height={157}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h206v157H0z" />
    <path
      d="M142.148 142.52H97.515c-4.092 0-7.44-3.333-7.44-7.408v-59.26c0-4.074 3.348-7.407 7.44-7.407h44.633c4.091 0 7.439 3.333 7.439 7.407v59.26c0 4.075-3.348 7.408-7.439 7.408Z"
      fill="#FFC37D"
    />
    <path
      d="M149.587 120.297v14.815c0 4.075-3.348 7.408-7.439 7.408H97.515c-4.092 0-7.44-3.333-7.44-7.408v-14.815h59.512Z"
      fill="#ABD354"
    />
    <path d="M142.148 127.705H97.515v7.408h44.633v-7.408Z" fill="#1B2D93" />
    <path
      d="M101.606 127.705h-4.091v7.408h4.091v-7.408ZM131.734 101.038c2.875 0 5.207-2.322 5.207-5.185 0-2.864-2.332-5.185-5.207-5.185-2.876 0-5.208 2.321-5.208 5.185 0 2.864 2.332 5.185 5.208 5.185ZM121.319 112.89v-1.852c0-4.815 4.463-8.518 10.415-8.518 5.579 0 10.414 3.703 10.414 8.518v1.852h-20.829Z"
      fill="#1B2D93"
    />
    <path
      d="M97.678 105.713v7.407h14.878v-7.407H97.678ZM97.368 94.509v7.408h22.316v-7.408H97.368Z"
      fill="#fff"
    />
    <path
      d="M108.301 127.705h-4.835v7.408h4.835v-7.408ZM111.648 127.705h-1.487v7.408h1.487v-7.408ZM126.898 127.705h-6.323v7.408h6.323v-7.408ZM131.733 127.705h-3.347v7.408h3.347v-7.408ZM135.081 127.705h-1.86v7.408h1.86v-7.408ZM138.801 127.705h-2.232v7.408h2.232v-7.408ZM142.148 127.705h-1.488v7.408h1.488v-7.408ZM122.063 78.816v-.741c0-.37 0-.37.372-.37v-.371h-.372v-.74c0-.371-.372-.371-.372-.371h-1.116s0-.37-.372-.37h-1.116s-.372 0-.372.37v.37H117.6c-.372 0-.372 0-.372.37v.371h-.372v.37c.372 0 .372 0 .372.37v.742c0 .37.372.37.372.37h.372v2.222h-.372c-.372 0-.372 0-.372.37v.37h-.372v.371h.372v.37s0 .371.372.371h4.091s.372 0 .372-.37v-.37h.372v-.371h-.372v-.37s0-.37-.372-.37h-.372v-1.853h.372s.372-.37.372-.74ZM118.716 127.705h-5.208v7.408h5.208v-7.408Z"
      fill="#1B2D93"
    />
    <path
      d="M109.255 97.79v39.316c0 3.604-2.961 6.553-6.58 6.553H50.03c-3.619 0-6.58-2.949-6.58-6.553V97.789c0-3.604 2.961-6.553 6.58-6.553h52.645c3.619 0 6.58 2.95 6.58 6.553Z"
      fill="#B5CDFF"
    />
    <path
      d="M52.92 110.892c-.66 0-1.144.226-1.47.6v-.462h-1.48v6.88h1.48v-2.428c.326.374.81.6 1.47.6 1.294 0 2.36-1.13 2.36-2.595 0-1.465-1.066-2.595-2.36-2.595Zm-.295 3.794c-.681 0-1.175-.462-1.175-1.199 0-.737.493-1.199 1.175-1.199.68 0 1.174.462 1.174 1.199 0 .737-.493 1.199-1.174 1.199ZM59.702 111.03v.462c-.325-.374-.809-.6-1.47-.6-1.293 0-2.36 1.13-2.36 2.595 0 1.465 1.067 2.595 2.36 2.595.661 0 1.145-.226 1.47-.6v.462h1.481v-4.914h-1.48Zm-1.174 3.656c-.681 0-1.175-.462-1.175-1.199 0-.737.494-1.199 1.175-1.199.68 0 1.174.462 1.174 1.199 0 .737-.493 1.199-1.174 1.199ZM65.162 110.892c-.652 0-1.146.236-1.412.6v-.462h-1.48v4.914h1.48v-2.683c0-.698.375-1.012.918-1.012.474 0 .859.285.859.894v2.801h1.48v-3.017c0-1.327-.849-2.035-1.846-2.035ZM52.338 125.911c.968 0 1.807-.501 2.231-1.268l-1.293-.737c-.158.344-.523.55-.957.55-.642 0-1.116-.462-1.116-1.14 0-.678.474-1.14 1.116-1.14.434 0 .79.206.957.55l1.293-.747c-.424-.756-1.273-1.258-2.23-1.258-1.5 0-2.616 1.131-2.616 2.595 0 1.465 1.115 2.595 2.615 2.595ZM58.652 120.859v.462c-.326-.374-.81-.6-1.47-.6-1.294 0-2.36 1.131-2.36 2.595 0 1.465 1.066 2.595 2.36 2.595.66 0 1.144-.226 1.47-.599v.461h1.48v-4.914h-1.48Zm-1.175 3.656c-.68 0-1.174-.462-1.174-1.199 0-.737.493-1.199 1.174-1.199.681 0 1.175.462 1.175 1.199 0 .737-.494 1.199-1.175 1.199ZM62.7 121.734v-.875h-1.481v4.914h1.48v-2.221c0-.973.869-1.219 1.481-1.12v-1.671c-.622 0-1.283.314-1.48.973ZM68.31 118.893v2.428c-.325-.374-.809-.6-1.47-.6-1.293 0-2.36 1.131-2.36 2.595 0 1.465 1.067 2.595 2.36 2.595.661 0 1.145-.226 1.47-.599v.461h1.481v-6.88h-1.48Zm-1.174 5.622c-.681 0-1.175-.462-1.175-1.199 0-.737.494-1.199 1.175-1.199s1.175.462 1.175 1.199c0 .737-.494 1.199-1.175 1.199Z"
      fill="#1B2D93"
    />
    <path
      d="M78.327 100.41v-.655c0-.328 0-.328.329-.328V99.1h-.33v-.328c0-.328-.328-.328-.328-.328h-.988s0-.655-.329-.655h-.987s-.329 0-.329.328v.327h-.987c-.329 0-.329 0-.329.328v.328h-.329v.327c.33 0 .33 0 .33.328v.655c0 .328.328.328.328.328h.33v1.966h-.33c-.329 0-.329 0-.329.327v.328h-.329v.328h.33v.327s0 .328.328.328h3.62s.329 0 .329-.328v-.327h.329v-.328h-.33v-.655h-.657v-1.966h.329s.329 0 .329-.328Z"
      fill="#568AF7"
    />
    <path d="M50.054 130.511v6.552h26.322v-6.552H50.054Z" fill="#FF9518" />
    <path
      d="M50.026 97.838v6.553h19.742v-6.553H50.026ZM82.9 97.808v6.553h19.741v-6.553H82.899Z"
      fill="#568AF7"
    />
    <path
      d="M92.146 124.655c2.907 0 5.264-2.347 5.264-5.242s-2.357-5.242-5.264-5.242c-2.908 0-5.265 2.347-5.265 5.242s2.357 5.242 5.265 5.242ZM81.288 137.106v-1.638c0-4.915 4.606-8.846 10.529-8.846 5.922 0 10.529 3.931 10.529 8.846v1.638H81.288Z"
      fill="#fff"
    />
    <path
      d="M149.439 79.533c8.298 0 15.026-6.699 15.026-14.963 0-8.263-6.728-14.963-15.026-14.963-8.299 0-15.027 6.7-15.027 14.963 0 8.264 6.728 14.963 15.027 14.963Z"
      fill="#00BA88"
    />
    <path
      d="M156.625 62.621h-5.226v-5.205a1.95 1.95 0 0 0-.574-1.38 1.965 1.965 0 0 0-2.772 0 1.946 1.946 0 0 0-.574 1.38v5.205h-5.227c-.519 0-1.018.206-1.385.572a1.947 1.947 0 0 0 0 2.76c.367.366.866.571 1.385.571h5.227v5.205c0 .517.206 1.014.574 1.38a1.965 1.965 0 0 0 2.772 0 1.95 1.95 0 0 0 .574-1.38v-5.205h5.226c.52 0 1.019-.205 1.386-.571a1.946 1.946 0 0 0 0-2.76 1.963 1.963 0 0 0-1.386-.572Z"
      fill="#fff"
    />
  </svg>
);

export default AddDocumentSvg;
