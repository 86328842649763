import * as React from "react"

const ChevronRightArrow = (props) => (
  <svg
    width={18}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.667 7c0-.46.394-.834.88-.834H15.62c.486 0 .88.373.88.834 0 .46-.394.833-.88.833H1.546c-.485 0-.88-.373-.88-.833Z"
      fill="#1B2D93"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.056 13.382a.931.931 0 0 1 .044-1.256L15.275 7 10.1 1.873a.931.931 0 0 1-.044-1.256.797.797 0 0 1 1.178-.046l5.833 5.777c.17.168.266.404.266.651a.917.917 0 0 1-.266.652l-5.833 5.778a.797.797 0 0 1-1.178-.047Z"
      fill="#1B2D93"
    />
  </svg>
)

export default ChevronRightArrow
