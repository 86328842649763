import * as React from "react";

function SvgPhone(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 75 75" {...props}>
      <path d="M55.67 75c-1.37-.23-2.75-.41-4.11-.7a53.45 53.45 0 01-14.43-5.91 77.1 77.1 0 01-19-14.88C11.42 46.22 5.84 38.19 2.32 28.85A32.09 32.09 0 010 18.3a12.7 12.7 0 013.48-9.71c2.1-2.15 4.13-4.38 6.39-6.34 3.49-3 7.29-3 10.66.14 3.56 3.34 7 6.85 10.31 10.42a7.08 7.08 0 010 9.63c-1.76 2-3.71 3.82-5.57 5.73-.17.16-.35.3-.66.58A54.26 54.26 0 0046 50.08l4.44-4.49a11.21 11.21 0 014-3 6.71 6.71 0 017.31 1.3q5.59 5.32 10.9 10.94a7.14 7.14 0 01.19 9.53c-2.24 2.64-4.72 5.14-7.23 7.56a11.14 11.14 0 01-6.48 2.89 4.91 4.91 0 00-.51.15zm1.11-4.51a8.57 8.57 0 006.65-2.62c1.68-1.76 3.4-3.48 5.1-5.22a8.37 8.37 0 00.62-.7 3.06 3.06 0 00-.09-4.4c-1.37-1.48-2.82-2.88-4.24-4.31-1.85-1.85-3.68-3.71-5.55-5.53a3 3 0 00-4.37-.12c-.28.23-.53.49-.79.75-1.78 1.77-3.54 3.56-5.33 5.31a3.39 3.39 0 01-4.25.71c-1.09-.55-2.17-1.1-3.22-1.72a55.44 55.44 0 01-12.42-10.36 44.77 44.77 0 01-8.22-11.68c-1.06-2.34-.83-3.48 1-5.26s3.79-3.67 5.6-5.59a2.86 2.86 0 00.07-4.19c-.22-.26-.45-.5-.68-.74L17.88 6C16 4.15 14.33 4.13 12.45 6S8.8 9.52 7.09 11.39a10 10 0 00-2 3A12.74 12.74 0 005 21.74a44.51 44.51 0 003.9 10.75A73.42 73.42 0 0020.26 49a71.65 71.65 0 0028.68 19.8 26.13 26.13 0 007.84 1.65z" />
    </svg>
  );
}

export default SvgPhone;
