import React from "react";

const SvgKebabMenu = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="4" r="3" fill="white" />
    <circle cx="13" cy="12" r="3" fill="white" />
    <circle cx="13" cy="20" r="3" fill="white" />
  </svg>

);

export default SvgKebabMenu;
