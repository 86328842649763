import React from "react";

const SvgMgLogo = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.19354 13.9312L8.54859 14.8661L8.5486 14.8661L8.19354 13.9312ZM7.80662 13.9312L7.45156 14.8661L7.45157 14.8661L7.80662 13.9312ZM3.33341 3H12.6667V1H3.33341V3ZM12.3334 2.66667V6.77657H14.3334V2.66667H12.3334ZM3.66675 6.77657V2.66667H1.66675V6.77657H3.66675ZM12.3334 6.77657C12.3334 11.0098 8.74757 12.6511 7.83849 12.9964L8.5486 14.8661C9.55372 14.4843 14.3334 12.3853 14.3334 6.77657H12.3334ZM1.66675 6.77657C1.66675 12.3853 6.44644 14.4843 7.45156 14.8661L8.16168 12.9964C7.25259 12.6511 3.66675 11.0098 3.66675 6.77657H1.66675ZM7.83849 12.9964C7.9404 12.9577 8.05976 12.9577 8.16167 12.9964L7.45157 14.8661C7.80711 15.0011 8.19305 15.0011 8.54859 14.8661L7.83849 12.9964ZM12.6667 3C12.4827 3 12.3334 2.85076 12.3334 2.66667H14.3334C14.3334 1.74619 13.5872 1 12.6667 1V3ZM3.33341 1C2.41294 1 1.66675 1.74619 1.66675 2.66667H3.66675C3.66675 2.85076 3.51751 3 3.33341 3V1Z" fill="#FF9518"/>
<path d="M5.33203 7.26571L7.26473 9.19841L11.1301 5.33301" stroke="#FF9518" stroke-width="1.36662" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



);


export default SvgMgLogo;