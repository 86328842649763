import * as React from "react";

const Download = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4.195 6.47.943-.942 2.195 2.195v-4.39a.667.667 0 0 1 1.334 0v4.39l2.195-2.195.943.943L8 10.275 4.195 6.471Z"
      fill="#568AF7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.333 10c.369 0 .667.299.667.667v.666c0 .368.299.667.667.667h6.666a.667.667 0 0 0 .667-.667v-.666a.667.667 0 1 1 1.333 0v.666a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2v-.666c0-.368.298-.667.666-.667Z"
      fill="#568AF7"
    />
  </svg>
);

export default Download;
