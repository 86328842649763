import * as React from "react";

function SvgRefresh(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 800 800" {...props}>
      <path d="M299.66 160.64c110.09-51.89 241.93-29.72 329 57.35 58.35 58.35 88.06 136.95 84.77 216.89h62.11L671.59 628.02 567.63 434.88h68.17c3.12-59.57-18.91-118.82-62.06-161.97-63.61-63.61-160.5-80.12-241.01-42.18l-33.07-70.09zm-124.58 57.98L71.12 411.76h62.11c-3.31 79.61 26.11 158.23 84.77 216.89 86.26 86.27 216.03 108.82 325.65 58.86l-32.16-70.39c-80.04 36.46-175.77 19.4-238.57-43.39-43.19-43.19-65.14-102.65-62.1-161.97h68.22L175.08 218.62z" />
    </svg>
  );
}

export default SvgRefresh;
