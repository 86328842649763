import * as React from "react"

const InfoCircle = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.00016 0.332886C3.32016 0.332886 0.333496 3.32022 0.333496 6.99955C0.333496 10.6802 3.32016 13.6662 7.00016 13.6662C10.6868 13.6662 13.6668 10.6802 13.6668 6.99955C13.6668 3.32022 10.6868 0.332886 7.00016 0.332886Z" fill="#828792"/>
  <path d="M6.99341 3.88631C7.32008 3.88631 7.58008 4.15298 7.58008 4.47298C7.58008 4.79298 7.32008 5.05298 7.00008 5.05298C6.67341 5.05298 6.41341 4.79298 6.41341 4.47298C6.41341 4.15298 6.67341 3.88631 6.99341 3.88631Z" fill="white"/>
  <path d="M7.58691 9.53369C7.58691 9.85303 7.32025 10.1204 7.00025 10.1204C6.68025 10.1204 6.42025 9.85303 6.42025 9.53369L6.42025 6.58703C6.42025 6.26636 6.68025 6.00703 7.00025 6.00703C7.32025 6.00703 7.58691 6.26636 7.58691 6.58703L7.58691 9.53369Z" fill="white"/>
  </svg>
)

export default InfoCircle
