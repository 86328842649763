import * as React from "react";

function SvgSearch(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M21.707 20.293l-5.395-5.395a8.028 8.028 0 10-1.414 1.414l5.395 5.395a1 1 0 001.414-1.414zM4 10a6 6 0 116 6 6.007 6.007 0 01-6-6z" />
    </svg>
  );
}

export default SvgSearch;
