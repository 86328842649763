import * as React from "react";

const AddDocumentSvg = (props) => (
  <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect width="160" height="160" fill="white"/>
<path d="M55.1278 43.126C64.8315 45.9 71.8888 55.6091 81.8446 57.1222C94.9509 59.1397 104.403 47.0349 116.879 46.4044C129.481 45.7739 141.831 55.7352 146.494 66.8313C151.535 78.9361 149.519 93.3106 142.335 104.281C135.152 115.251 123.432 122.816 110.83 126.095C95.9591 130.003 79.8282 127.734 66.0917 120.799C51.3471 113.485 45.5501 96.4629 31.4356 90.2844C23.4961 86.7539 14.4225 82.5928 12.5322 74.1446C11.398 68.7227 13.7924 63.1746 17.4471 59.0136C25.5125 50.4393 43.0296 39.7215 55.1278 43.126Z" fill="#EEFEFF"/>
<rect x="55.5146" y="57.3359" width="3.64793" height="6.07988" fill="white"/>
<g clipPath="url(#clip0_229_5103)">
<path d="M66.368 103.123C66.4024 94.9945 66.4024 86.8832 66.4024 78.7549C66.5402 76.4011 65.6621 75.5882 63.045 75.6221C57.7765 75.7237 52.508 75.656 47.2395 75.656C45.3111 75.656 44.5708 76.3841 44.5708 78.2807V89.9652V111.929C44.5708 113.52 45.3628 114.418 46.7918 114.418C49.0129 114.452 55.3833 114.418 57.6043 114.418C61.999 114.418 63.8887 114.062 65.6965 110.912C65.8859 110.303 66.1614 109.693 66.2475 109.05C66.5229 107.085 66.368 105.104 66.368 103.123Z" fill="#1B2D93"/>
<path d="M111.236 85.9519C112.596 84.3263 112.975 82.4127 112.407 80.4315C111.529 77.3664 109.084 75.673 105.813 75.673C102.025 75.673 98.2542 75.673 94.4664 75.6561C96.4464 72.1508 97.3244 68.4084 97.2039 64.395C97.0834 60.3478 94.3803 56.9949 90.3686 56.0974C87.0113 55.3523 84.4975 56.9441 84.1532 60.1107C83.7227 64.1241 81.9321 67.477 79.1601 70.3727C77.2146 72.4048 74.9247 74.0135 72.5659 75.5206C70.5343 76.6552 68.5026 77.7898 66.3849 78.755C66.3849 86.8833 66.3849 94.9947 66.3504 103.123C66.3504 105.104 66.5054 107.085 66.2299 109.05C66.1438 109.693 65.8683 110.303 65.679 110.913C66.0405 111.065 66.4021 111.2 66.7636 111.353C72.5315 113.656 78.5748 114.401 84.7386 114.452C90.1965 114.486 95.6371 114.452 101.095 114.435C101.749 114.435 102.438 114.401 103.058 114.232C107.242 113.182 109.222 109.151 107.465 105.274C107.293 104.901 107.362 104.715 107.672 104.478C110.289 102.496 111.029 99.6176 109.669 96.6034C109.497 96.2308 109.566 96.0446 109.876 95.8075C112.854 93.5553 113.423 89.779 111.184 86.8325C110.943 86.4938 110.943 86.2906 111.236 85.9519Z" fill="#FFBC80"/>
<mask id="mask0_229_5103" style={{'mask-type':'alpha'}} maskUnits="userSpaceOnUse" x="65" y="55" width="48" height="60">
<path d="M111.236 85.9519C112.596 84.3263 112.975 82.4127 112.407 80.4315C111.529 77.3664 109.084 75.673 105.813 75.673C102.025 75.673 98.2544 75.673 94.4666 75.6561C96.4466 72.1508 97.3247 68.4084 97.2042 64.395C97.0837 60.3478 94.3805 56.9949 90.3689 56.0974C87.0115 55.3523 84.4978 56.9441 84.1534 60.1107C83.723 64.1241 81.9324 67.477 79.1604 70.3727C77.2148 72.4048 74.9249 74.0135 72.5661 75.5206C70.5345 76.6552 68.5028 77.7898 66.3851 78.755C66.3851 86.8833 66.3851 94.9947 66.3507 103.123C66.3507 105.104 66.5056 107.085 66.2302 109.05C66.1441 109.693 65.8686 110.303 65.6792 110.913C66.0408 111.065 66.4023 111.2 66.7639 111.353C72.5317 113.656 78.575 114.401 84.7388 114.452C90.1967 114.486 95.6374 114.452 101.095 114.435C101.75 114.435 102.438 114.401 103.058 114.232C107.242 113.182 109.222 109.151 107.466 105.274C107.294 104.901 107.362 104.715 107.672 104.478C110.289 102.496 111.03 99.6176 109.67 96.6034C109.497 96.2308 109.566 96.0446 109.876 95.8075C112.855 93.5553 113.423 89.779 111.185 86.8325C110.944 86.4938 110.944 86.2906 111.236 85.9519Z" fill="#FFBC80"/>
</mask>
<g mask="url(#mask0_229_5103)">
<path d="M106.89 85.9519C108.25 84.3263 108.629 82.4127 108.061 80.4315C107.183 77.3664 104.738 75.673 101.466 75.673C97.6786 75.673 93.908 75.673 90.1202 75.6561C92.1002 72.1508 92.9783 68.4084 92.8577 64.395C92.7372 60.3478 90.0341 56.9949 86.0224 56.0974C82.6651 55.3523 80.1513 56.9441 79.807 60.1107C79.3766 64.1241 77.5859 67.477 74.814 70.3727C72.8684 72.4048 70.5785 74.0135 68.2197 75.5206C66.1881 76.6552 64.1564 77.7898 62.0387 78.755C62.0387 86.8833 62.0387 94.9947 62.0042 103.123C62.0042 105.104 62.1592 107.085 61.8837 109.05C61.7976 109.693 61.5222 110.303 61.3328 110.913C61.6943 111.065 62.0559 111.2 62.4175 111.353C68.1853 113.656 74.2286 114.401 80.3924 114.452C85.8503 114.486 91.291 114.452 96.7489 114.435C97.4031 114.435 98.0918 114.401 98.7116 114.232C102.895 113.182 104.875 109.151 103.119 105.274C102.947 104.901 103.016 104.715 103.326 104.478C105.943 102.496 106.683 99.6176 105.323 96.6034C105.151 96.2308 105.22 96.0446 105.53 95.8075C108.508 93.5553 109.076 89.779 106.838 86.8325C106.597 86.4938 106.597 86.2906 106.89 85.9519Z" fill="#FFD4AD"/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M56.3702 55.9171C56.854 55.9171 57.3369 55.9851 57.7962 56.1387C60.6258 57.0556 61.6455 60.1501 60.7937 62.8549C60.3108 64.2371 59.5211 65.4986 58.4869 66.5294C57.0065 67.9582 55.382 69.2266 53.6333 70.3192L53.4416 70.4346L53.2423 70.3115C51.4875 69.2265 49.8537 67.9582 48.3596 66.5217C47.3323 65.491 46.5419 64.2371 46.0512 62.8549C45.1849 60.1501 46.2045 57.0556 49.0649 56.1227C49.2872 56.0462 49.5164 55.9928 49.7464 55.963H49.8384C50.0538 55.9316 50.2677 55.9171 50.4824 55.9171H50.5667C51.0497 55.9316 51.5174 56.0157 51.9704 56.1693H52.0157C52.0463 56.1838 52.0693 56.1998 52.0847 56.2143C52.2541 56.2686 52.4143 56.3297 52.5677 56.4138L52.859 56.5437C52.9294 56.5811 53.0084 56.6382 53.0767 56.6877C53.12 56.719 53.1589 56.7472 53.1886 56.7652C53.2012 56.7726 53.2139 56.78 53.2267 56.7875C53.2924 56.8257 53.3609 56.8655 53.4186 56.9096C54.2704 56.2609 55.3046 55.9095 56.3702 55.9171ZM58.4094 61.4181C58.7237 61.4097 58.992 61.1583 59.015 60.8367V60.7457C59.038 59.6753 58.3871 58.7056 57.3974 58.3312C57.0831 58.2235 56.7381 58.3924 56.6231 58.7133C56.5157 59.0342 56.6844 59.3857 57.0064 59.4995C57.4978 59.6829 57.8267 60.165 57.8267 60.6991V60.7228C57.8121 60.8978 57.865 61.0666 57.9724 61.1965C58.0797 61.3264 58.2407 61.4021 58.4094 61.4181Z" fill="#FB5247"/>
<mask id="mask1_229_5103" style={{'mask-type':'alpha'}} maskUnits="userSpaceOnUse" x="44" y="75" width="23" height="40">
<path d="M66.368 103.124C66.4024 94.9955 66.4024 86.8842 66.4024 78.7559C66.5402 76.402 65.6621 75.5892 63.045 75.6231C57.7765 75.7247 52.508 75.6569 47.2395 75.6569C45.3111 75.6569 44.5708 76.3851 44.5708 78.2817V89.9661V111.929C44.5708 113.521 45.3628 114.419 46.7918 114.419C49.0129 114.453 55.3833 114.419 57.6043 114.419C61.999 114.419 63.8887 114.063 65.6965 110.913C65.8859 110.304 66.1614 109.694 66.2475 109.051C66.5229 107.086 66.368 105.105 66.368 103.124Z" fill="#1B2D93"/>
</mask>
<g mask="url(#mask1_229_5103)">
<path d="M67.5484 96.7617C65.3413 98.2619 63.0726 99.6878 61.1421 101.54C59.3348 103.274 57.6721 105.134 55.9682 106.967C54.8098 108.213 53.6333 109.45 52.501 110.719C51.8435 111.457 51.1506 112.272 50.8012 113.21C50.373 114.359 50.1756 115.565 49.9005 116.753C49.8126 117.133 50.2792 117.144 50.5642 117.03C51.4001 116.697 52.198 115.716 52.8328 115.127C54.131 113.922 55.48 112.719 56.6725 111.408C58.5076 109.391 59.9297 107.029 62.0224 105.239C63.49 103.984 65.5417 102.836 67.5213 102.836C68.3944 102.836 67.5337 104.019 67.3182 104.321C65.8012 106.444 63.5541 107.882 61.8734 109.842C60.5317 111.407 59.3638 113.122 58.0134 114.688C57.6577 115.101 57.431 115.239 57.431 115.775C57.431 116.165 57.4874 116.298 57.8576 116.497C59.2789 117.26 61.1513 115.226 61.9683 114.405C63.3276 113.038 64.4964 111.519 65.9028 110.193C66.3311 109.789 67.235 109.24 67.4265 108.668" stroke="#8EA1E6" strokeLinecap="round"/>
</g>
</g>
<rect x="124.825" y="42.7441" width="7.29585" height="4.8639" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M127.781 39.1867C128.414 39.1867 129.047 39.276 129.648 39.4779C133.354 40.6828 134.69 44.7495 133.574 48.304C132.942 50.1205 131.907 51.7783 130.553 53.1328C128.614 55.0105 126.486 56.6773 124.196 58.1132L123.945 58.2648L123.684 58.1032C121.385 56.6773 119.246 55.0105 117.289 53.1228C115.943 51.7682 114.908 50.1205 114.265 48.304C113.131 44.7495 114.466 40.6828 118.212 39.4568C118.504 39.3564 118.804 39.2861 119.105 39.2469H119.225C119.508 39.2058 119.788 39.1867 120.069 39.1867H120.179C120.812 39.2058 121.424 39.3162 122.018 39.518H122.077C122.117 39.5371 122.147 39.5582 122.168 39.5773C122.389 39.6486 122.599 39.7289 122.8 39.8394L123.182 40.0101C123.274 40.0592 123.377 40.1344 123.467 40.1993C123.523 40.2404 123.574 40.2775 123.613 40.3012C123.63 40.3109 123.646 40.3206 123.663 40.3304C123.749 40.3807 123.839 40.433 123.915 40.491C125.03 39.6385 126.385 39.1766 127.781 39.1867ZM130.451 46.4157C130.863 46.4046 131.214 46.0743 131.245 45.6515V45.5321C131.275 44.1253 130.422 42.8511 129.126 42.3591C128.714 42.2175 128.262 42.4394 128.112 42.8611C127.971 43.2828 128.192 43.7447 128.614 43.8943C129.257 44.1353 129.688 44.7689 129.688 45.4708V45.5019C129.669 45.7319 129.738 45.9538 129.879 46.1245C130.02 46.2952 130.23 46.3946 130.451 46.4157Z" fill="#FF9393"/>
<path d="M136.985 88.187L132.303 91.1053L129.833 96.2471L127.171 91.2095L122.393 88.4996L127.043 85.616L129.545 80.4395L132.206 85.5118L136.985 88.187Z" fill="#FFC053"/>
<path d="M37.2749 105.922L34.9259 107.239L33.7159 109.623L32.3635 107.31L29.979 106.064L32.3279 104.712L33.538 102.327L34.8904 104.676L37.2749 105.922Z" fill="#FFC053"/>
<path d="M78.6181 51.2029L76.2692 52.5198L75.0592 54.9043L73.7068 52.5909L71.3223 51.3453L73.6712 49.9929L74.8812 47.6084L76.2336 49.9573L78.6181 51.2029Z" fill="#FFC053"/>
<defs>
<clipPath id="clip0_229_5103">
<rect width="68.0946" height="69.3106" fill="white" transform="translate(44.5708 45.1758)"/>
</clipPath>
</defs>
</svg>

);

export default AddDocumentSvg;
