import * as React from "react";

function SvgCross(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#DF5626" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M22.229 18.1L13.723 3.96c-.773-1.28-2.695-1.28-3.446 0L1.772 18.1C.998 19.38 1.97 21 3.495 21h16.988c1.547 0 2.519-1.6 1.746-2.9zM11.956 6.903c.64-.021 1.17.448 1.215 1.066v.107l-.287 5.929c-.022.469-.442.831-.906.81-.464-.021-.817-.384-.84-.81l-.287-5.95c-.044-.598.464-1.13 1.105-1.152zM12 18.547c-.729 0-1.325-.575-1.325-1.28 0-.703.596-1.279 1.325-1.279.73 0 1.326.576 1.326 1.28 0 .704-.597 1.28-1.326 1.28z"
                  transform="translate(-174 -949) translate(100 805) translate(58 128) translate(16 16)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgCross;
