import React from "react";

function DocumentIcon(props) {
  return (
    <svg width="16" height="16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.207 1.333h5.587c2.06 0 3.206 1.187 3.206 3.22v6.887c0 2.067-1.147 3.227-3.206 3.227H5.207C3.18 14.667 2 13.507 2 11.44V4.553c0-2.033 1.18-3.22 3.207-3.22Zm.18 3.107v-.007h1.992a.522.522 0 0 1 0 1.047H5.387a.52.52 0 0 1 0-1.04Zm0 4.053h5.226a.52.52 0 0 0 0-1.04H5.387a.52.52 0 0 0 0 1.04Zm0 3.047h5.226a.522.522 0 0 0 .467-.52c0-.273-.2-.5-.467-.527H5.387a.53.53 0 0 0-.5.807c.106.167.3.267.5.24Z"
        fill="#fff"
      />
    </svg>
  );
}

export default DocumentIcon;
