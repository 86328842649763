import * as React from "react";

const AddDocumentSvg = (props) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_229_4516)">
<rect width="100" height="100" fill="white"/>
<path d="M66.4864 78.6251C60.4055 76.2235 56.4666 69.5053 50.1439 67.9029C41.8219 65.7769 34.9678 73.0082 26.8855 72.6332C18.7219 72.2504 11.3869 65.0372 9.07884 57.5728C6.59048 49.4325 8.79424 40.2679 14.1147 33.6279C19.4352 26.9878 27.4661 22.8329 35.7962 21.5042C45.6282 19.9103 55.8841 22.389 64.303 27.7331C73.3481 33.3848 76.0147 44.751 84.7249 49.6297C89.6209 52.4097 92.2808 55.4976 92.9681 61.0767C94.2006 68.6672 91.027 73.3602 88.4094 75.8205C82.6709 80.8567 74.0713 81.5843 66.4864 78.6251Z" fill="#FFF8BB"/>
<path d="M81.5832 27.9998V65.9998C81.5832 69.4832 78.6957 72.3332 75.1665 72.3332H23.8332C20.304 72.3332 17.4165 69.4832 17.4165 65.9998V27.9998C17.4165 24.5165 20.304 21.6665 23.8332 21.6665H75.1665C78.6957 21.6665 81.5832 24.5165 81.5832 27.9998Z" fill="#B5CDFF"/>
<path d="M26.6516 40.6641C26.0067 40.6641 25.5351 40.8826 25.2175 41.2436V40.7971H23.7737V47.4471H25.2175V45.1006C25.5351 45.4616 26.0067 45.6801 26.6516 45.6801C27.9125 45.6801 28.952 44.5876 28.952 43.1721C28.952 41.7566 27.9125 40.6641 26.6516 40.6641ZM26.3629 44.3311C25.6987 44.3311 25.2175 43.8846 25.2175 43.1721C25.2175 42.4596 25.6987 42.0131 26.3629 42.0131C27.027 42.0131 27.5082 42.4596 27.5082 43.1721C27.5082 43.8846 27.027 44.3311 26.3629 44.3311Z" fill="#1B2D93"/>
<path d="M33.2644 40.7971V41.2436C32.9468 40.8826 32.4752 40.6641 31.8303 40.6641C30.5694 40.6641 29.5299 41.7566 29.5299 43.1721C29.5299 44.5876 30.5694 45.6801 31.8303 45.6801C32.4752 45.6801 32.9468 45.4616 33.2644 45.1006V45.5471H34.7082V40.7971H33.2644ZM32.1191 44.3311C31.4549 44.3311 30.9737 43.8846 30.9737 43.1721C30.9737 42.4596 31.4549 42.0131 32.1191 42.0131C32.7832 42.0131 33.2644 42.4596 33.2644 43.1721C33.2644 43.8846 32.7832 44.3311 32.1191 44.3311Z" fill="#1B2D93"/>
<path d="M38.5875 40.6641C37.9523 40.6641 37.471 40.8921 37.2111 41.2436V40.7971H35.7674V45.5471H37.2111V42.9536C37.2111 42.2791 37.5769 41.9751 38.1063 41.9751C38.5683 41.9751 38.9436 42.2506 38.9436 42.8396V45.5471H40.3874V42.6306C40.3874 41.3481 39.5596 40.6641 38.5875 40.6641Z" fill="#1B2D93"/>
<path d="M26.0838 55.1801C27.0271 55.1801 27.8452 54.6956 28.2591 53.9546L26.9982 53.2421C26.8442 53.5746 26.4881 53.7741 26.0646 53.7741C25.439 53.7741 24.977 53.3276 24.977 52.6721C24.977 52.0166 25.439 51.5701 26.0646 51.5701C26.4881 51.5701 26.8346 51.7696 26.9982 52.1021L28.2591 51.3801C27.8452 50.6486 27.0175 50.1641 26.0838 50.1641C24.6208 50.1641 23.5332 51.2566 23.5332 52.6721C23.5332 54.0876 24.6208 55.1801 26.0838 55.1801Z" fill="#1B2D93"/>
<path d="M32.24 50.2971V50.7436C31.9224 50.3826 31.4507 50.1641 30.8059 50.1641C29.545 50.1641 28.5055 51.2566 28.5055 52.6721C28.5055 54.0876 29.545 55.1801 30.8059 55.1801C31.4507 55.1801 31.9224 54.9616 32.24 54.6006V55.0471H33.6837V50.2971H32.24ZM31.0946 53.8311C30.4305 53.8311 29.9492 53.3846 29.9492 52.6721C29.9492 51.9596 30.4305 51.5131 31.0946 51.5131C31.7587 51.5131 32.24 51.9596 32.24 52.6721C32.24 53.3846 31.7587 53.8311 31.0946 53.8311Z" fill="#1B2D93"/>
<path d="M36.1867 51.1426V50.2971H34.7429V55.0471H36.1867V52.9001C36.1867 51.9596 37.0337 51.7221 37.6304 51.8171V50.2021C37.0241 50.2021 36.3792 50.5061 36.1867 51.1426Z" fill="#1B2D93"/>
<path d="M41.6582 48.3971V50.7436C41.3406 50.3826 40.869 50.1641 40.2241 50.1641C38.9632 50.1641 37.9237 51.2566 37.9237 52.6721C37.9237 54.0876 38.9632 55.1801 40.2241 55.1801C40.869 55.1801 41.3406 54.9616 41.6582 54.6006V55.0471H43.102V48.3971H41.6582ZM40.5128 53.8311C39.8487 53.8311 39.3675 53.3846 39.3675 52.6721C39.3675 51.9596 39.8487 51.5131 40.5128 51.5131C41.177 51.5131 41.6582 51.9596 41.6582 52.6721C41.6582 53.3846 41.177 53.8311 40.5128 53.8311Z" fill="#1B2D93"/>
<path d="M51.4248 30.5333V29.9C51.4248 29.5833 51.4248 29.5833 51.7456 29.5833V29.2667H51.4248V28.95C51.4248 28.6333 51.1039 28.6333 51.1039 28.6333H50.1414C50.1414 28.6333 50.1414 28 49.8206 28H48.8581C48.8581 28 48.5373 28 48.5373 28.3167V28.6333H47.5748C47.2539 28.6333 47.2539 28.6333 47.2539 28.95V29.2667H46.9331V29.5833C47.2539 29.5833 47.2539 29.5833 47.2539 29.9V30.5333C47.2539 30.85 47.5748 30.85 47.5748 30.85H47.8956V32.75H47.5748C47.2539 32.75 47.2539 32.75 47.2539 33.0667V33.3833H46.9331V33.7H47.2539V34.0167C47.2539 34.0167 47.2539 34.3333 47.5748 34.3333H51.1039C51.1039 34.3333 51.4248 34.3333 51.4248 34.0167V33.7H51.7456V33.3833H51.4248V33.0667V32.75H50.7831V30.85H51.1039C51.1039 30.85 51.4248 30.85 51.4248 30.5333Z" fill="#568AF7"/>
<path d="M23.8564 59.6257V65.959H49.5231V59.6257H23.8564Z" fill="#FF9518"/>
<path d="M23.8296 28.047V34.3804H43.0796V28.047H23.8296Z" fill="#568AF7"/>
<path d="M55.8838 28.0187V34.3521H75.1338V28.0187H55.8838Z" fill="#568AF7"/>
<path d="M64.8999 53.9663C67.735 53.9663 70.0333 51.6979 70.0333 48.8997C70.0333 46.1014 67.735 43.833 64.8999 43.833C62.0649 43.833 59.7666 46.1014 59.7666 48.8997C59.7666 51.6979 62.0649 53.9663 64.8999 53.9663Z" fill="white"/>
<path d="M54.3125 66V64.4167C54.3125 59.6667 58.8042 55.8667 64.5792 55.8667C70.3542 55.8667 74.8458 59.6667 74.8458 64.4167V66H54.3125Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_229_4516">
<rect width="100" height="100" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default AddDocumentSvg;
