import React from "react";

const SvgResendGray = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16.728 9.33606C18.576 11.6641 18.432 14.9761 16.488 17.1841C14.664 19.2481 11.616 19.7281 9.21601 18.5041C3.91201 15.8401 5.61601 7.70406 11.4 7.08006C12.912 6.91206 12.936 4.51206 11.4 4.68006C7.60801 5.06406 4.48801 7.68006 3.69601 11.4721C2.92801 15.1921 4.92001 19.0081 8.28001 20.6881C11.688 22.3921 15.912 21.4561 18.384 18.6241C21.168 15.4561 20.952 10.8481 18.408 7.60806C17.472 6.43206 15.792 8.13606 16.728 9.33606Z"
      fill="#B4B7BD"
    />
    <path
      d="M13.824 5.20806C12.72 5.85606 11.616 6.48006 10.512 7.12806C11.112 7.46406 11.712 7.82406 12.312 8.16006C12.312 6.64806 12.312 5.13606 12.312 3.62406C11.712 3.96006 11.112 4.32006 10.512 4.65606C11.832 5.42406 13.128 6.16806 14.448 6.93606C14.448 6.24006 14.448 5.54406 14.448 4.87206C14.232 4.96806 14.016 5.08806 13.824 5.20806C12.48 5.97606 13.704 8.06406 15.024 7.27206C15.24 7.15206 15.432 7.03206 15.648 6.91206C16.416 6.45606 16.416 5.28006 15.648 4.84806C14.328 4.08006 13.032 3.33606 11.712 2.56806C10.92 2.11206 9.91201 2.68806 9.91201 3.60006C9.91201 5.11206 9.91201 6.62406 9.91201 8.13606C9.91201 9.04806 10.92 9.62406 11.712 9.16806C12.816 8.52006 13.92 7.89606 15.024 7.24806C16.368 6.50406 15.168 4.44006 13.824 5.20806Z"
      fill="#B4B7BD"
    />
  </svg>
);

export default SvgResendGray;
