import * as React from "react";

function SvgPlusCircle(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 20 20" {...props}>
      <path d="M10 1.667c4.595 0 8.333 3.738 8.333 8.333 0 4.595-3.738 8.333-8.333 8.333-4.595 0-8.333-3.738-8.333-8.333 0-4.595 3.738-8.333 8.333-8.333zm0 1.666A6.675 6.675 0 003.333 10 6.675 6.675 0 0010 16.667 6.675 6.675 0 0016.667 10 6.675 6.675 0 0010 3.333zm0 2.5l.833.834v2.5h2.5l.834.833-.834.833h-2.5v2.5l-.833.834-.833-.834v-2.5h-2.5L5.833 10l.834-.833h2.5v-2.5L10 5.833z" />
    </svg>
  );
}

export default SvgPlusCircle;
