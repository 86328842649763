import * as React from "react";

const AddDocumentSvg = (props) => (
  <svg width="107" height="100" viewBox="0 0 107 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M38.7789 18.1486C45.8996 21.2883 50.2714 29.4623 57.7169 31.6627C67.5159 34.579 76.005 26.2976 85.6005 27.1201C95.2923 27.9558 103.68 36.8767 106.078 45.8623C108.658 55.6601 105.61 66.4577 98.9728 74.1074C92.3354 81.7571 82.5899 86.3248 72.6203 87.517C60.852 88.9547 48.7693 85.5288 39.0049 78.7805C28.5101 71.637 25.8681 57.994 15.7355 51.7855C10.0418 48.251 7.02201 44.4544 6.46442 37.7867C5.35218 28.7012 9.34544 23.2673 12.5734 20.463C19.6333 14.7403 29.8954 14.2755 38.7789 18.1486Z" fill="#CBFCFF"/>
<path d="M87.4593 29.3425V68.0271C87.4593 71.5732 84.5579 74.4745 81.0118 74.4745H29.4323C25.8862 74.4745 22.9849 71.5732 22.9849 68.0271V29.3425C22.9849 25.7964 25.8862 22.895 29.4323 22.895H81.0118C84.5579 22.895 87.4593 25.7964 87.4593 29.3425Z" fill="#FFE6C8"/>
<rect x="70.5347" y="42.1289" width="11.9913" height="15.0482" fill="#FFC401"/>
<path d="M33.9453 31.9213V31.2765C33.9453 30.9541 33.9453 30.9541 34.2677 30.9541V30.6318H33.9453V30.3094C33.9453 29.987 33.623 29.987 33.623 29.987H32.6558C32.6558 29.987 32.6558 29.3423 32.3335 29.3423H31.3664C31.3664 29.3423 31.044 29.3423 31.044 29.6647V29.987H30.0769C29.7545 29.987 29.7545 29.987 29.7545 30.3094V30.6318H29.4321V30.9541C29.7545 30.9541 29.7545 30.9541 29.7545 31.2765V31.9213C29.7545 32.2436 30.0769 32.2436 30.0769 32.2436H30.3992V34.1779H30.0769C29.7545 34.1779 29.7545 34.1779 29.7545 34.5002V34.8226H29.4321V35.145H29.7545V35.4674C29.7545 35.4674 29.7545 35.7897 30.0769 35.7897H33.623C33.623 35.7897 33.9453 35.7897 33.9453 35.4674V35.145H34.2677V34.8226H33.9453V34.5002V34.1779H33.3006V32.2436H33.623C33.623 32.2436 33.9453 32.2436 33.9453 31.9213Z" fill="#1B2D93"/>
<path d="M29.4321 61.5379V67.9854H55.2219V61.5379H29.4321Z" fill="#568AF7"/>
<path d="M67.3164 61.5379V67.9854H82.5259V61.5379H67.3164Z" fill="#568AF7"/>
<path d="M37.604 29.3426V35.79H70.5351V29.3426H37.604Z" fill="#FF9518"/>
<path d="M29.4321 47.3953V53.8428H38.8468V47.3953H29.4321Z" fill="#FFC401"/>
<path d="M76.7175 50.0572C78.3732 50.0572 79.7154 48.715 79.7154 47.0593C79.7154 45.4037 78.3732 44.0615 76.7175 44.0615C75.0619 44.0615 73.7197 45.4037 73.7197 47.0593C73.7197 48.715 75.0619 50.0572 76.7175 50.0572Z" fill="#1B2D93"/>
<path d="M70.5347 57.1768V56.24C70.5347 53.4295 73.1578 51.1812 76.5303 51.1812C79.9029 51.1812 82.526 53.4295 82.526 56.24V57.1768H70.5347Z" fill="#1B2D93"/>
<path d="M44.5496 48.2031H42.1426V54.2207H44.5496C46.2088 54.2207 47.4725 52.9141 47.4725 51.2119C47.4725 49.5098 46.2088 48.2031 44.5496 48.2031ZM44.5496 52.8969H43.518V49.527H44.5496C45.4952 49.527 46.1486 50.1803 46.1486 51.2119C46.1486 52.2435 45.4952 52.8969 44.5496 52.8969Z" fill="#1B2D93"/>
<path d="M49.6213 52.8969V48.2031H48.2458V54.2207H51.7704V52.8969H49.6213Z" fill="#1B2D93"/>
<g clipPath="url(#clip0_235_2061)">
<path d="M81.5661 32.2554L81.1611 32.876C80.5139 32.876 79.9098 33.1068 79.4379 33.4969C78.966 33.887 78.6267 34.4359 78.5093 35.0723L78.3563 35.9009L77.798 36.2926L77.2397 35.9009L77.0868 35.0723C76.8518 33.7996 75.7291 32.876 74.4348 32.876L74.1289 32.2554L74.7022 31.2156C75.728 31.0579 76.763 30.979 77.798 30.979C78.833 30.979 79.868 31.0579 80.8938 31.2156L81.5661 32.2554Z" fill="#1B2D93"/>
<path fillRule="evenodd" clipRule="evenodd" d="M73.0698 32.5655C73.0698 29.9543 75.1868 27.8374 77.7979 27.8374C80.4091 27.8374 82.5261 29.9543 82.5261 32.5655C82.5261 35.1767 80.4091 37.2937 77.7979 37.2937C75.1868 37.2937 73.0698 35.1767 73.0698 32.5655ZM77.2388 35.8967L77.0867 35.0725C76.8517 33.7998 75.729 32.8762 74.4348 32.8761C74.5746 34.4097 75.7397 35.647 77.2388 35.8967ZM74.6978 31.2236C75.2168 30.026 76.4096 29.1882 77.7979 29.1882C79.1851 29.1882 80.3769 30.0245 80.8967 31.2203L80.8937 31.2158C79.868 31.058 78.8329 30.9792 77.7979 30.9792C76.7629 30.9792 75.7279 31.058 74.7021 31.2158L74.6978 31.2236ZM81.1612 32.8759C81.0214 34.4096 79.8563 35.647 78.357 35.8967L78.5092 35.0725C78.6266 34.436 78.9659 33.8871 79.4378 33.4971C79.9097 33.107 80.5138 32.8761 81.161 32.8761L81.1612 32.8759Z" fill="#1B2D93"/>
<path d="M77.798 33.2405C78.171 33.2405 78.4734 32.9381 78.4734 32.5651C78.4734 32.192 78.171 31.8896 77.798 31.8896C77.425 31.8896 77.1226 32.192 77.1226 32.5651C77.1226 32.9381 77.425 33.2405 77.798 33.2405Z" fill="#FFC401"/>
</g>
<defs>
<clipPath id="clip0_235_2061">
<rect width="9.45625" height="9.45625" fill="white" transform="translate(73.0698 27.8374)"/>
</clipPath>
</defs>
</svg>

);

export default AddDocumentSvg;
