import * as React from "react";

function SvgTrash(props) {
  return (
    <svg width={24} height={24} viewBox="1 -1 100 100" {...props}>
      <path d="M65.1 17.8V6.5H35.8v11.3H11.4v6.1h7.3l5.2 67.7h53.3l5.2-67.7h8.3v-6.1H65.1zm-6-5.3v5.2H41.9v-5.2h17.2zM29.4 85.4l-4.7-61.6h51.5l-4.8 61.6h-42z" />
      <path d="M47.4 32.6h6.1v44.3h-6.1zM64.701 77.086l-6.09-.331 2.407-44.334 6.09.331zM36.186 77.085l-2.401-44.333 6.09-.33 2.402 44.333z" />
    </svg>
  );
}

export default SvgTrash;
