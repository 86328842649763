import * as React from "react"

const SvgBikeLogo = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M2.667 12.667a2.568 2.568 0 0 1-1.884-.784A2.568 2.568 0 0 1 0 10c0-.633.203-1.195.609-1.683A2.604 2.604 0 0 1 2.167 7.4L1.7 7H0V6h3l1.667 1 2.666-1h2.1L8.4 4.667H6.667V3.333h2.366l1.4 1.8L12.667 4v2h-1.534L12.3 7.533a2.8 2.8 0 0 1 1.033-.2c.734 0 1.361.261 1.884.784.522.522.783 1.15.783 1.883 0 .733-.261 1.361-.783 1.883a2.568 2.568 0 0 1-1.884.784 2.568 2.568 0 0 1-1.883-.784A2.568 2.568 0 0 1 10.667 10c0-.3.053-.592.158-.875.106-.283.253-.547.442-.792l-.334-.4-2.266 3.4h-2l-1.334-1.166a2.655 2.655 0 0 1-.85 1.775 2.556 2.556 0 0 1-1.816.725Zm0-1.334c.366 0 .68-.13.941-.391C3.869 10.68 4 10.367 4 10s-.13-.68-.392-.942a1.283 1.283 0 0 0-.941-.391c-.367 0-.68.13-.942.39a1.284 1.284 0 0 0-.392.943c0 .367.131.68.392.942.261.26.575.391.942.391Zm10.666 0c.367 0 .681-.13.942-.391.261-.261.392-.575.392-.942s-.13-.68-.392-.942a1.285 1.285 0 0 0-.942-.391c-.366 0-.68.13-.942.39-.26.262-.391.576-.391.943s.13.68.391.942c.262.26.576.391.942.391ZM7.967 10l1.766-2.667H7.567l-2.4.9 2 1.767h.8Z"
        fill="#FF9518"
      />
    </g>
  </svg>
)

export default SvgBikeLogo
