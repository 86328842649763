import * as React from "react";

const AddDocumentSvg = (props) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect width="100" height="100" fill="white"/>
<path d="M34.8291 73.9619C40.9006 72.3617 45.3162 66.7607 51.5455 65.8879C59.7459 64.724 65.6598 71.707 73.466 72.0707C81.3511 72.4344 89.0785 66.688 91.9959 60.2869C95.15 53.304 93.8884 45.0117 89.3939 38.6833C84.8994 32.355 77.5662 27.9907 69.6811 26.0994C60.3767 23.8445 50.2838 25.1538 41.6891 29.1545C32.4635 33.3734 28.8364 43.1932 20.0051 46.7574C15.0375 48.7941 9.36024 51.1945 8.17748 56.068C7.46782 59.1958 8.96599 62.3964 11.2527 64.7968C16.2991 69.743 27.2594 75.9259 34.8291 73.9619Z" fill="#CBFCFF"/>
<path d="M68.2502 79.1668H32.7502C29.496 79.1668 26.8335 76.5418 26.8335 73.3335V26.6668C26.8335 23.4585 29.496 20.8335 32.7502 20.8335H68.2502C71.5043 20.8335 74.1668 23.4585 74.1668 26.6668V73.3335C74.1668 76.5418 71.5043 79.1668 68.2502 79.1668Z" fill="#FFC37D"/>
<path d="M74.1668 61.667V73.3337C74.1668 76.542 71.5043 79.167 68.2502 79.167H32.7502C29.496 79.167 26.8335 76.542 26.8335 73.3337V61.667H74.1668Z" fill="#ABD354"/>
<path d="M68.25 67.5H32.75V73.3333H68.25V67.5Z" fill="#1B2D93"/>
<path d="M36.0042 67.5H32.75V73.3333H36.0042V67.5Z" fill="#1B2D93"/>
<path d="M59.9669 46.5002C62.2542 46.5002 64.1085 44.672 64.1085 42.4168C64.1085 40.1617 62.2542 38.3335 59.9669 38.3335C57.6795 38.3335 55.8252 40.1617 55.8252 42.4168C55.8252 44.672 57.6795 46.5002 59.9669 46.5002Z" fill="#1B2D93"/>
<path d="M51.6836 55.8337V54.3753C51.6836 50.5837 55.2336 47.667 59.9669 47.667C64.4044 47.667 68.2503 50.5837 68.2503 54.3753V55.8337H51.6836Z" fill="#1B2D93"/>
<path d="M32.8799 50.1813V56.0146H44.7132V50.1813H32.8799Z" fill="white"/>
<path d="M32.6333 41.3586V47.1919H50.3833V41.3586H32.6333Z" fill="white"/>
<path d="M41.3292 67.5H37.4834V73.3333H41.3292V67.5Z" fill="#1B2D93"/>
<path d="M43.9919 67.5H42.8086V73.3333H43.9919V67.5Z" fill="#1B2D93"/>
<path d="M56.121 67.5H51.0918V73.3333H56.121V67.5Z" fill="#1B2D93"/>
<path d="M59.9667 67.5H57.3042V73.3333H59.9667V67.5Z" fill="#1B2D93"/>
<path d="M62.6296 67.5H61.1504V73.3333H62.6296V67.5Z" fill="#1B2D93"/>
<path d="M65.5875 67.5H63.8125V73.3333H65.5875V67.5Z" fill="#1B2D93"/>
<path d="M68.2502 67.5H67.0669V73.3333H68.2502V67.5Z" fill="#1B2D93"/>
<path d="M52.275 29.0003V28.417C52.275 28.1253 52.275 28.1253 52.5708 28.1253V27.8337H52.275V27.2503C52.275 26.9587 51.9791 26.9587 51.9791 26.9587H51.0916C51.0916 26.9587 51.0916 26.667 50.7958 26.667H49.9083C49.9083 26.667 49.6125 26.667 49.6125 26.9587V27.2503H48.725C48.4291 27.2503 48.4291 27.2503 48.4291 27.542V27.8337H48.1333V28.1253C48.4291 28.1253 48.4291 28.1253 48.4291 28.417V29.0003C48.4291 29.292 48.725 29.292 48.725 29.292H49.0208V31.042H48.725C48.4291 31.042 48.4291 31.042 48.4291 31.3337V31.6253H48.1333V31.917H48.4291V32.2087C48.4291 32.2087 48.4291 32.5003 48.725 32.5003H51.9791C51.9791 32.5003 52.275 32.5003 52.275 32.2087V31.917H52.5708V31.6253H52.275V31.3337C52.275 31.3337 52.275 31.042 51.9791 31.042H51.6833V29.5837H51.9791C51.9791 29.5837 52.275 29.292 52.275 29.0003Z" fill="#1B2D93"/>
<path d="M49.6129 67.5H45.4712V73.3333H49.6129V67.5Z" fill="#1B2D93"/>
</svg>

);

export default AddDocumentSvg;
