import * as React from "react";

function SvgChevronRight(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 1000 1000" {...props}>
      <path d="M725 500c0 7-3 13-7 18L418 818c-7 6-16 9-25 7-8-3-15-10-18-18-2-9 1-18 7-25l282-282-282-282c-6-6-9-16-7-24 3-9 10-16 18-18 9-3 18 0 25 6l300 300c4 5 7 11 7 18" />
    </svg>
  );
}

export default SvgChevronRight;
